export const getPerson = (id, state) => {
  for (var i in state) {
    if (id == state[i].Person.PId) {
      return state[i].Person;
    }
  }
};

export const getPersonsName = (state) => {
  var names = "";
  for (var i in state) {
    names += state[i].Person.FName + " " + state[i].Person.LName + ";";
  }
  return names;
};

export const getMyPerson = (id, family) => {
  var userinfo = family;

  if (id == userinfo?.PersonInfo.Person.PId) {
    return userinfo?.PersonInfo.Person;
  }

  for (var i in userinfo?.RelaInfos) {
    if (id == userinfo?.RelaInfos[i].Person.PId) {
      return userinfo?.RelaInfos[i].Person;
    }
  }
};

export const checkIsCoach = (person, id) => {
  var rt = false;
  for (var i in person) {
    if (person[i].PType == 11 && person[i].Person.PId == id) {
      rt = true;
    }
  }
  return rt;
};


