import React from "react";
import Constant from "../../../../common/constants/define";
import { getRosters } from "../../../../services/activities/getRosters";
import Communicate from "../../../../common/components/communicate";
import { checkIsCoach, getMyPerson } from "../../../../common/utils/family";

class MyStuff_roster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      node: this.props.node,
      myroster: [],
      mypid: this.props.mypid,
      toGo: 0,
      family: this.props.family,
    };
    this.handleCommunicate = this.handleCommunicate.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  componentWillMount() {
    this.getMyRoster();
  }

  handleBackClick() {
    this.setState({ toGo: 0 });
  }

  async getMyRoster() {
    this.setState({ loading: true });

    const response = await getRosters(
      this.state.node.Group?.GId,
      this.state.node.Act.AId,
      this.state.node.Act.AType
    );

    this.setState({ myroster: response, loading: false });
  }

  getPersoninfo(person) {
    let cls =
      this.state.mypid == person.Person.PId
        ? "row cdi-open-item listseled"
        : "row cdi-open-item";

    return (
      <div>
        <div className={cls}>
          <div id="roster-gender" className="col-7 sscol ta-left">
            <span className="roster-info">
              {person.Person.FName + " " + person.Person.LName}
            </span>
          </div>
          <div id="roster-number" className="col-5 sscol">
            <span className="roster-info">
              {this.props.orginfo.SupP === 1 ? "*******" : person.Person.Mobi}
            </span>
          </div>
        </div>
      </div>
    );
  }

  getCoachinfo(person) {
    let cls =
      this.state.mypid == person.Person.PId
        ? "row cdi-open-item listseled"
        : "row cdi-open-item";

    return (
      <div className={cls}>
        <div id="roster-gender" className="col-7 sscol ta-left">
          <span className="roster-info">
            {person.Person.FName + " " + person.Person.LName}
          </span>
        </div>
        <div id="roster-number" className="col-5 sscol">
          <span className="roster-info">
            {this.props.orginfo.SupP === 1 ? "*******" : person.Person.Num}
          </span>
        </div>
      </div>
    );
  }

  getPlayers() {
    const items = [];
    for (let i = 0; i < this.state.myroster.length; i++) {
      if (
        this.state.myroster[i].PType == 10 ||
        this.state.myroster[i].PType == 12 ||
        this.state.myroster[i].PType == 15
      )
        items.push(this.getPersoninfo(this.state.myroster[i]));
    }

    return <div>{items}</div>;
  }

  getCoaches() {
    const items = [];
    for (let i = 0; i < this.state.myroster.length; i++) {
      if (
        this.state.myroster[i].PType == 11 ||
        this.state.myroster[i].PType == 13 ||
        this.state.myroster[i].PType == 25
      )
        items.push(this.getCoachinfo(this.state.myroster[i]));
    }

    return <div>{items}</div>;
  }

  handleCommunicate() {
    this.setState({ toGo: 1 });
  }

  renderRoster() {
    const isCoach = checkIsCoach(this.state.myroster, this.state.mypid);
    return (
      <div>
        {this.state.toGo == 1 ? (
          <Communicate
            myroster={this.state.myroster}
            mypid={this.state.mypid}
            onBackClick={this.handleBackClick}
            family={this.props.family}
          />
        ) : (
          <div>
            <div id="cd-participant-container">
              {this.state.node.Act.AType === Constant.TP_CLASS && (
                <p>TEACHERS</p>
              )}
              {this.state.node.Act.AType === Constant.TP_SPORT && (
                <p>COACHES</p>
              )}
              {this.state.node.Act.AType === Constant.TP_EVENT && (
                <p>MANAGERS</p>
              )}
              <div className="activity-card-header rounded row">
                <div className="col-7 sscol ta-left">
                  <span className="activity-column-titles">Name</span>
                </div>
                <div className="col-5 sscol">
                  <span className="activity-column-titles">Phone</span>
                </div>
              </div>
              {this.getCoaches()}
            </div>
            <div id="cd-participant-container">
              {this.state.node.Act.AType === Constant.TP_CLASS && (
                <p>STUDENTS</p>
              )}
              {this.state.node.Act.AType === Constant.TP_SPORT && (
                <p>PLAYERS</p>
              )}
              {this.state.node.Act.AType === Constant.TP_EVENT && (
                <p>PARTICIPANTS</p>
              )}
              <div className="activity-card-header rounded row">
                <div className="col-7 sscol ta-left">
                  <span className="activity-column-titles">Name</span>
                </div>
                <div className="col-5 sscol">
                  <span className="activity-column-titles">Phone</span>
                </div>
              </div>
              {this.getPlayers()}
            </div>
            {isCoach && (
              <div className="form-actions-area">
                <a>
                  <button
                    onClick={this.handleCommunicate}
                    className="filled-button-continue"
                    type="button"
                  >
                    Communicate
                  </button>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.state.node.Act.AType === Constant.TP_SPORT &&
        this.state.node.Group?.Type !== Constant.TP_TEAM_UNP &&
        this.state.node.Group?.Type !== Constant.TP_TEAM_UNC &&
        (this.state.node.Group?.App & Constant.APP_PUBED) ===
          Constant.APP_PUBED ? (
          this.renderRoster()
        ) : this.state.node.Act.AType === Constant.TP_SPORT ? (
          <div>
            <p style={{ textAlign: "center", color: "red" }}>
              Not yet assigned to a team
            </p>
          </div>
        ) : (
          this.renderRoster()
        )}
      </div>
    );
  }
}

export default MyStuff_roster;
