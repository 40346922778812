import React from "react";
import InputMask from "react-input-mask";
import Checkbox from "../../common/components/register-checkbox";
import Radiobtn from "../../common/components/radiobtn";
import MemberCard from "../../common/components/cards";
import { ssm_getcookie } from "../../common/utils/ssm_util";
import Constant from "../../common/constants/define";
import Spinner from "../../common/components/Spinner";
import ErrorMsg from "../../common/components/ErrorMsg";
import Price from "./price";
import Count from "./count";
import Price2 from "./price2";
import CustomQuestion from "./customquestion";
import Prerequisite from "./prerequisite";
import ssm_valid from "../../common/utils/ssm_valid";
import { insert, removeItem } from "../../common/utils/utils";
import { Add } from "@mui/icons-material";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../common/components/modal";
import BackTitle from "../../common/components/back/BackTitle";
import { checkEmailExist } from "../../services/common/checkEmailExist";
import { registerNode } from "../../services/common/registerNode";
import {
  checkMaxTeamMembers,
  checkRegister,
} from "../../services/register/checkRegister";
import { check_gend, getfeeAmou, getfeeName } from "../../common/utils/node";
import {
  formatCompleteDateENGB,
  formatDateEN,
  formatDefaultBDay,
} from "../../common/utils/date";
import { getOrganization } from "../../services/main/getOrganization";
import TeamsSelect from "./teams";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invid: 0,
      C_MulSlots: this.props.mulslots,
      errormsg: "",
      selregpersoned: false,
      selgardianed: false,
      cqerrors: {},
      prevtxt: "Prev",
      regtxt: "Continue",
      node: this.props.node,
      loading: false,
      toGo: 0,
      toWaiver: 0,
      family: this.props.family,
      selregperson: {},
      selparent: {},
      regdetail: {},
      totsteps: 1,
      curstep: 0,
      customquestions: [],
      pcds: this.props.pcds,
      selectError: false,
      inputsEnable: true,
      inputFocus: false,
      modal: false,
      isChildFlow: false,
      teamMember: {
        FName: "",
        LName: "",
        Emai: "",
        PId: 0,
        RId: "00000000-0000-0000-0000-000000000000",
        Num: "",
        Gend: "1",
        BDay: "01/01/1970",
        Grad: "0",
        Disa: false,
        Type: 10,
      },
      isGroupPage: false,
      groupChecks: { Coach: false, Player: false },
      groupPersons: [],
      players: [],
      coachs: [],
      isExistingEmail: false,
      groupError: "",
      modalError: "",
      feeError: "",
      updateState: 0,
      schools: [],
      selectedTeamID: 0,
      maxMembersReached: false,
      adultsListLength: 0,
    };

    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handlePriceOkClick = this.handlePriceOkClick.bind(this);
    this.handlePriceOkClick2 = this.handlePriceOkClick2.bind(this);
    this.handleCountOkClick = this.handleCountOkClick.bind(this);
    this.handlePrerOkClick = this.handlePrerOkClick.bind(this);
    this.getMainContent = this.getMainContent.bind(this);
    this.getRegContent = this.getRegContent.bind(this);
    this.handleRegChange = this.handleRegChange.bind(this);
    this.handleReg2Change = this.handleReg2Change.bind(this);
    this.handleteamnameChange = this.handleteamnameChange.bind(this);
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.getCarrierOptions = this.getCarrierOptions.bind(this);
    this.getCarrierStepTwo = this.getCarrierStepTwo.bind(this);
    this.handleCheckboxChange4 = this.handleCheckboxChange4.bind(this);
    this.handleSelChange4 = this.handleSelChange4.bind(this);
    this.getTeamMembers = this.getTeamMembers.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.handleGroupCheckboxChange = this.handleGroupCheckboxChange.bind(this);
    this.handleAddNewMember = this.handleAddNewMember.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleTeamChange = this.handleTeamChange.bind(this);
  }

  defaultPerson = {};
  defaultDetail = {};

  requireds = [];
  requireds2 = [];
  cqrequireds = [];
  errors = {};

  orgid = 0;
  regsteptitle = "Participant Info";
  buttonDOM = React.createRef();
  buttonDOM2 = React.createRef();

  componentWillReceiveProps(props) {
    this.setState({ invid: props.invid });
    this.setState({ pcds: props.pcds });
  }

  async checkEmail(val) {
    const response = await checkEmailExist(val);
    if (response.Data === "1") {
      this.setState({ isExistingEmail: true });
    } else {
      this.setState({ isExistingEmail: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const newCurrentMember = {
      OrgId: this.orgid,
      FName: this.state.selregperson.FName,
      LName: this.state.selregperson.LName,
      Emai: this.state.selregperson.Emai,
      PId: this.state.selregperson.PId,
      RId: "00000000-0000-0000-0000-000000000000",
      Num: this.state.selregperson.Num,
      Gend: this.state.selregperson.Gend,
      BDay: this.state.selregperson.BDay,
      Grad: this.state.teamMember.Grad,
      Disa: true,
    };

    if (prevState.toGo !== this.state.toGo) {
      if (this.state.toGo === 2) {
        this.setState({ isGroupPage: true });
      } else {
        this.setState({ isGroupPage: false });
      }
    }

    if (prevState.groupPersons !== this.state.groupPersons) {
      if (this.state.groupPersons.length !== 0) {
        this.setState({ groupError: "" });
      }
    }

    if (prevState.groupChecks.Player !== this.state.groupChecks.Player) {
      const newPlayer = {
        ...newCurrentMember,
        Type: 10,
      };
      if (this.state.groupChecks.Player === true) {
        if (
          this.state.node?.Activity.MaxGMemb === 0 ||
          this.state.players.length < this.state.node?.Activity.MaxGMemb
        ) {
          const newGroupPersons = insert(this.state.groupPersons, 0, newPlayer);
          const newPlayers = insert(this.state.players, 0, newPlayer);
          this.setState({
            groupPersons: newGroupPersons,
            players: newPlayers,
          });
        }
      } else {
        this.setState({
          groupPersons: this.state.groupPersons.slice(1),
          players: this.state.players.slice(1),
        });
      }
    }

    if (prevState.groupChecks.Coach !== this.state.groupChecks.Coach) {
      const newCoach = {
        ...newCurrentMember,
        Type: 11,
      };
      if (this.state.groupChecks.Coach === true) {
        const newGroupPersons = insert(this.state.groupPersons, 1, newCoach);
        const newCoachs = insert(this.state.coachs, 0, newCoach);
        this.setState({
          groupPersons: newGroupPersons,
          coachs: newCoachs,
        });
      } else if (
        this.state.groupChecks.Coach === false &&
        this.state.groupChecks.Player === true
      ) {
        const unwantendElements = this.state.groupPersons.slice(0, 1);
        const removedFirstElements = this.state.groupPersons.slice(2);
        const newGroupPersons = unwantendElements.concat(removedFirstElements);
        this.setState({
          groupPersons: newGroupPersons,
          coachs: this.state.coachs.slice(1),
        });
      } else {
        this.setState({
          groupPersons: this.state.groupPersons.slice(1),
          coachs: this.state.coachs.slice(1),
        });
      }
    }
  }

  handleteamnameChange(event) {
    this.setState({ teamname: event.target.value });
  }

  handleCheckboxChange4 = (event) => {
    let name = event.target.id;

    if (this.state.selparent.Sms) {
      this.Del_Required("Gate");
      this.errors["Gate"] = "";
    } else {
      this.requireds.push("Gate");
    }

    this.setState((prevState) => ({
      selparent: {
        ...prevState.selparent,
        [name]: prevState.selparent[name] ? 0 : 1,
      },
    }));
  };

  handleCheckboxChange2 = (event) => {
    let name = event.target.id;
    this.setState((prevState) => ({
      regdetail: {
        ...prevState.regdetail,
        [name]: !prevState.regdetail[name],
      },
    }));
  };

  Del_Required(name) {
    for (var i in this.requireds) {
      if (this.requireds[i] == name) {
        this.requireds.splice(i, 1);
      }
    }
  }

  handleCheckboxChange = (event) => {
    let name = event.target.id;
    if (this.state.selregperson.Sms) {
      this.Del_Required("Gate");
      this.errors["Gate"] = "";
    } else {
      this.requireds.push("Gate");
    }
    this.setState((prevState) => ({
      selregperson: {
        ...prevState.selregperson,
        [name]: prevState.selregperson[name] ? 0 : 1,
      },
    }));
  };

  handleRadioChange = (event) => {
    let id = event.target.id;
    ssm_valid.ck_radvalid(
      this.requireds,
      "Gend",
      this.state.selregperson.Gend,
      this.errors
    );

    if (!check_gend(id, this.state.node)) {
      this.errors["Gend"] = "Your gender is not in the scope of this Activity.";
    } else {
      this.errors["Gend"] = "";
    }

    this.setState((prevState) => ({
      selregperson: {
        ...prevState.selregperson,
        Gend: id,
      },
    }));
  };

  handleRadioChange2 = (event) => {
    let id = event.target.id;

    this.setState((prevState) => ({
      selparent: {
        ...prevState.selparent,
        Gend: id,
      },
    }));
  };

  handleAnswerChange(CQs, errors) {
    this.setState({ customquestions: CQs, cqerrors: errors });
  }

  cal_age(date, birday) {
    var date_b = new Date(birday);
    var date_n = new Date(date);
    var os = (date_n - date_b) / 3600000 / 24 / 365;
    return os;
  }

  check_age(birday) {
    if (birday == "NaN/NaN/NaN") return true;
    var bage = true;
    var ce_Node = this.state.node;
    var co_OType = this.state.node.OType;
    if (co_OType == Constant.TP_VENUE || co_OType == Constant.TP_ITEM) {
      bage = true;
    } else if (
      co_OType == Constant.TP_SPORT ||
      co_OType == Constant.TP_CLASS ||
      co_OType == Constant.TP_EVENT
    ) {
      var gatev = this.g_Gate_m(ce_Node, 0);
      switch (gatev) {
        case 1:
          bage = true;
          break;
        case 2:
          var ageby = this.g_Gate_m(ce_Node, 1);
          var dwlim = ce_Node.Activity.DLowe / 12;
          var uplim = 0;
          if (ce_Node.Activity.DUppe == 500 || ce_Node.Activity.DUppe == 252)
            ce_Node.Activity.DUppe = 5000;
          if (ce_Node.Activity.DUppe <= 18) uplim = ce_Node.Activity.DUppe / 12;
          else uplim = (ce_Node.Activity.DUppe + 12) / 12;
          if (ageby == 1) {
            if (
              this.cal_age(Date(), birday) >= dwlim &&
              this.cal_age(Date(), birday) <= uplim
            ) {
              bage = true;
            } else {
              bage = false;
            }
          } else if (ageby == 2) {
            if (ce_Node.Activity.AEnd != "" && ce_Node.Activity.AStart != "") {
              if (
                (this.cal_age(ce_Node.Activity.AEnd, birday) >= dwlim &&
                  this.cal_age(ce_Node.Activity.AEnd, birday) <= uplim) ||
                (this.cal_age(ce_Node.Activity.AStart, birday) >= dwlim &&
                  this.cal_age(ce_Node.Activity.AStart, birday) <= uplim)
              ) {
                bage = true;
              } else {
                bage = false;
              }
            } else {
              bage = false;
            }
          } else if (ageby == 3) {
            if (ce_Node.Activity.VAge != "") {
              if (
                this.cal_age(ce_Node.Activity.VAge, birday) >= dwlim &&
                this.cal_age(ce_Node.Activity.VAge, birday) <= uplim
              ) {
                bage = true;
              } else {
                bage = false;
              }
            } else {
              bage = false;
            }
          }
          break;
        case 3:
          bage = true;
          break;
      }
    }
    return bage;
  }

  handleChange = (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;

    if (name == "BDay") {
      if (!this.check_age(val) && this.state.toGo !== 1) {
        if (this.errors["BDay"] == "")
          this.errors["BDay"] =
            "Your age is not in the scope of this Activity.";
      }
    }

    if (name == "Num") {
      if (val != "") {
        if (!val.match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/)) {
          this.errors["Num"] = "Please enter a valid phone number.";
        } else {
          this.errors["Num"] = "";
        }
      }
    }

    this.setState((prevState) => ({
      selregperson: {
        ...prevState.selregperson,
        [name]: val,
      },
    }));
  };

  handleChange3 = (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;

    if (name == "BDay") {
      if (!this.check_age(val) && this.state.toGo !== 1) {
        if (this.errors["BDay"] == "")
          this.errors["BDay"] =
            "Your age is not in the scope of this Activity.";
      }
    }

    if (name == "Num") {
      if (val != "") {
        if (!val.match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/)) {
          this.errors["Num"] = "Please enter a valid phone number.";
        } else {
          this.errors["Num"] = "";
        }
      }
    }

    this.setState((prevState) => ({
      selparent: {
        ...prevState.selparent,
        [name]: val,
      },
    }));
  };

  handleChange2 = (event) => {
    ssm_valid.ck_valid(this.requireds2, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;

    if (name == "EConP") {
      if (val != "") {
        if (!val.match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/)) {
          this.errors["EConP"] = "Please enter a valid phone number.";
        } else {
          this.errors["EConP"] = "";
        }
      }
    }

    this.setState((prevState) => ({
      regdetail: {
        ...prevState.regdetail,
        [name]: val,
      },
    }));
    if (name == "ECon") this.state.selregperson.EName = val;
    if (name == "EConP") this.state.selregperson.ENumber = val;
  };

  handleSelChange = (event) => {
    const { name, value } = event.target;
    ssm_valid.ck_selvalid(this.requireds, name, value, this.errors);
    this.setState((prevState) => ({
      selregperson: {
        ...prevState.selregperson,
        [name]: value,
      },
    }));
  };

  check_grad(grad) {
    var bgrad = true;
    var ce_Node = this.state.node;
    var co_OType = this.state.node.OType;
    if (co_OType == Constant.TP_VENUE || co_OType == Constant.TP_ITEM) {
      bgrad = true;
    } else if (
      co_OType == Constant.TP_SPORT ||
      co_OType == Constant.TP_CLASS ||
      co_OType == Constant.TP_EVENT
    ) {
      var gatev = this.g_Gate_m(ce_Node, 0);
      switch (gatev) {
        case 1:
          bgrad = true;
          break;
        case 2:
          bgrad = true;
          break;
        case 3:
          if (
            grad >= ce_Node.Activity.DLowe &&
            grad <= ce_Node.Activity.DUppe
          ) {
            bgrad = true;
          } else bgrad = false;
          break;
      }
    }
    return bgrad;
  }

  handleSelChange2 = (event) => {
    const { name, value } = event.target;
    ssm_valid.ck_selvalid(this.requireds2, name, value, this.errors);
    if (name == "Grad") {
      if (!this.check_grad(value)) {
        if (this.errors["Grad"] == "")
          this.errors["Grad"] =
            "Your grade is not in the scope of this Activity.";
      }
    }
    this.setState((prevState) => ({
      regdetail: {
        ...prevState.regdetail,
        [name]: value,
      },
    }));
  };

  handleSelChange3 = (event) => {
    const { name, value } = event.target;
    ssm_valid.ck_selvalid(this.requireds, name, value, this.errors);
    this.setState((prevState) => ({
      selregperson: {
        ...prevState.selregperson,
        [name]: value,
      },
    }));
  };

  handleSelChange4 = (event) => {
    const { name, value } = event.target;
    ssm_valid.ck_selvalid(this.requireds, name, value, this.errors);
    this.setState((prevState) => ({
      selparent: {
        ...prevState.selparent,
        [name]: value,
      },
    }));
  };

  async getOrgRess() {
    let req = {};
    req.Guid = this.orgid;
    req.Type = 1;
    let sid = ssm_getcookie("ssm_sid");
    let jsonstr = JSON.stringify(req);
    jsonstr = new Buffer(jsonstr).toString("base64");
    const para = { jsonstr, sid };
    let rt = await getOrganization(para);
    let orgres = JSON.parse(rt.Data);
    let schools = JSON.parse(orgres?.Schoinfo);
    let schoolData = JSON.parse(schools.Data);
    this.setState({ schools: schoolData });
  }

  componentDidMount() {
    this.defaultPerson = {
      Gate: this.state.family.PersonInfo.Person.Gate,
      Sms: this.state.family.PersonInfo?.Phones[0]?.Sms ?? 0,
      PId: 0,
      Emai: this.state.family.PersonInfo.Person.Emai,
      FName: "",
      LName: this.state.family.PersonInfo.Person.LName,
      BDay: "",
      Gend: "1",
      Addr: this.state.family.PersonInfo.Person.Addr,
      City: this.state.family.PersonInfo.Person.City,
      Regi: this.state.family.PersonInfo.Person.Regi,
      PCode: this.state.family.PersonInfo.Person.PCode,
      Num: this.state.family.PersonInfo.Person.Num,
    };

    this.defaultDetail = {
      ECon: this.state.family.PersonInfo.Person.EName,
      EConP: this.state.family.PersonInfo.Person.ENumber,
      Grad: undefined,
      Scho: undefined,
      Heig: "",
      Heig2: "",
      Weig: "",
      Budd: "",
      PCoa: "",
      W2Coa: false,
      W2Vol: false,
      W2Off: false,
      W2Com: false,
    };

    this.getOrgRess();

    this.requireds.push("FName");
    this.requireds.push("LName");
    this.requireds.push("Emai");
    if (this.state.node != null) {
      if (this.state.node.Common.ABirt == 3) this.requireds.push("BDay");
      if (this.state.node.Common.AGend == 3) this.requireds.push("Gend");
      if (this.state.node.Common.AAddr == 3) this.requireds.push("Addr");
      if (this.state.node.Common.ACity == 3) this.requireds.push("City");
      if (this.state.node.Common.AState == 3) this.requireds.push("Regi");
      if (this.state.node.Common.APCode == 3) this.requireds.push("PCode");
      if (this.state.node.Common.APhon == 3) this.requireds.push("Num");
      if (this.state.node.Common.AECont == 3) {
        this.requireds2.push("ECon");
        this.requireds2.push("EConP");
      }
      if (this.state.node.Common.AScho == 3) this.requireds2.push("Scho");
      if (this.state.node.Common.AGrad == 3) this.requireds2.push("Grad");
      if (this.state.node.Common.AWeig == 3) this.requireds2.push("Weig");
      if (this.state.node.Common.AHeig == 3) this.requireds2.push("Heig");
      if (this.state.node.Common.ABudd == 3) this.requireds2.push("Budd");
      if (this.state.node.Common.APref == 3) this.requireds2.push("PCoa");
    }

    this.setState({ customquestions: this.prepare_CQs() });
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");

    if (!this.state.node) {
      this.props.onNavigate("/");
    }
    if (this.state.node) {
      this.set_TotStep(this.state.node);
    }
  }

  handleSubmitClick() {
    if (this.state.isGroupPage) {
      if (!this.state.teamname) {
        this.errors["Teamname"] = "Team name is Required";
      } else {
        this.errors["Teamname"] = "";
      }

      if (this.state.groupPersons.length === 0) {
        this.setState({ groupError: "Team members need to be added" });
        return;
      } else {
        this.setState({ groupError: "" });
      }
    }

    let rqrt3 = ssm_valid.ck_allvalid(this.state.cqerrors);
    if (!rqrt3) {
      return;
    }

    if (this.state.curstep < this.state.totsteps - 1) {
      this.setState({ updateState: this.state.updateState + 1 });

      let rqrt = ssm_valid.ck_required(
        this.requireds,
        this.state.selregperson,
        this.errors
      );

      if (!rqrt) {
        return;
      }

      rqrt = ssm_valid.ck_required(
        this.requireds2,
        this.state.regdetail,
        this.errors
      );

      if (!rqrt) {
        return;
      }

      rqrt = ssm_valid.ck_allvalid(this.errors);
      if (!rqrt) {
        return;
      }

      if (this.state.curstep == this.state.totsteps - 2) {
        this.setState({ regtxt: "Submit" });
      } else {
        this.setState({ regtxt: "Continue" });
      }

      if (this.checkfees()) {
        if (this.state.regdetail.fee == undefined) {
          this.errors["Fee"] = "Required";
          return;
        } else {
          if (this.state.regdetail.fee.FId == 0) {
            this.errors["Fee"] = "Required";
            return;
          }
        }
      }
      let feeName = getfeeName(this.state.node, this.state.regdetail.fee?.FId);

      if (feeName === "Find Team" && this.state.selectedTeamID === 0) {
        this.errors["Teams"] = "Required";
        return;
      } else {
        this.errors["Teams"] = "";
      }

      if (
        feeName === "Find Team" &&
        this.state.selectedTeamID !== 0 &&
        this.state.maxMembersReached
      ) {
        this.setState({
          modal: true,
          modalError: `The max team member is limited to ${this.state.node.Activity.MaxGMemb}`,
        });
        return;
      }

      if (
        feeName === "Find Team" &&
        this.state.selectedTeamID !== 0 &&
        !this.state.maxMembersReached
      ) {
        this.setState({
          modal: false,
          modalError: "",
        });
      }

      if (this.state.curstep === 0) {
        if (this.state.node.OType === Constant.TP_VENUE) {
          if (this.state.node.Common.ABirt !== 1) {
            if (this.check_child2(this.state.selregperson.BDay)) {
              this.setState({ totsteps: this.state.totsteps + 1 });
              this.setState({ regtxt: "Continue" });
            }
          }
        } else {
          if (this.check_child(this.state.node, this.state.selregperson.BDay)) {
            this.setState({ totsteps: this.state.totsteps + 1 });
            this.setState({ regtxt: "Continue" });
          }
        }
      }

      let isCheckChild =
        this.state.node.OType === Constant.TP_VENUE
          ? this.check_child2(this.state.selregperson.BDay)
          : this.check_child(this.state.node, this.state.selregperson.BDay);

      if (isCheckChild) {
        this.setState({
          isChildFlow: true,
        });
      } else {
        this.setState({
          isChildFlow: false,
        });
      }

      if (this.state.curstep == 0) {
        if (isCheckChild)
          this.setState({
            toGo: this.state.toGo + 1,
            curstep: this.state.curstep + 1,
            inputsEnable: this.state.family.RelaInfos.length > 0 && true,
          });
        else if (this.check_group(this.state.node)) {
          if (feeName === "Free Agent" || feeName === "Find Team") {
            if (this.check_CQ(this.state.node)) {
              this.setState({
                toGo: this.state.toGo + 3,
                curstep: this.state.curstep + 1,
              });
            } else if (
              this.state.node.Waivers != undefined &&
              this.state.node.Waivers.length > 0
            ) {
              this.setState({
                toGo: 4,
                curstep: this.state.totsteps,
                toWaiver: this.state.toWaiver + 1,
              });
            } else {
              this.reg_Node();
            }
          } else {
            this.setState({
              toGo: this.state.toGo + 2,
              curstep: this.state.curstep + 1,
            });
          }
        } else if (this.check_CQ(this.state.node)) {
          if (feeName === "Free Agent" || feeName === "Find Team") {
            if (
              this.state.node.Waivers != undefined &&
              this.state.node.Waivers.length > 0
            ) {
              this.setState({
                toGo: 4,
                curstep: this.state.totsteps,
                toWaiver: this.state.toWaiver + 1,
              });
            } else {
              this.reg_Node();
            }
          } else {
            this.setState({
              toGo: this.state.toGo + 3,
              curstep: this.state.curstep + 1,
            });
          }
        } else this.reg_Node();
      } else if (this.state.curstep == 1) {
        if (
          this.state.selparent.BDay === "" ||
          this.state.selparent.BDay === "NaN/NaN/NaN"
        ) {
          this.errors["BDay"] = "Required";
          return;
        } else {
          this.errors["BDay"] = "";
        }

        let isParentAdult = this.check_child2(this.state.selparent.BDay);
        if (isParentAdult) {
          this.errors["BDay"] = "Guardian must be 18 or over.";
          return;
        } else {
          this.errors["BDay"] = "";
        }

        if (this.check_group(this.state.node)) {
          if (isCheckChild) {
            if (feeName === "Free Agent" || feeName === "Find Team") {
              if (this.check_CQ(this.state.node)) {
                this.setState({
                  toGo: this.state.toGo + 2,
                  curstep: this.state.curstep + 1,
                });
              } else if (
                this.state.node.Waivers != undefined &&
                this.state.node.Waivers.length > 0
              ) {
                this.setState({
                  toGo: 4,
                  curstep: this.state.totsteps,
                  toWaiver: this.state.toWaiver + 1,
                });
              } else {
                this.reg_Node();
              }
            } else {
              this.setState({
                toGo: this.state.toGo + 1,
                curstep: this.state.curstep + 1,
              });
            }
          } else {
            if (feeName === "Free Agent" || feeName === "Find Team") {
              if (
                this.state.node.Waivers != undefined &&
                this.state.node.Waivers.length > 0
              ) {
                this.setState({
                  toGo: 4,
                  curstep: this.state.totsteps,
                  toWaiver: this.state.toWaiver + 1,
                });
              } else {
                this.reg_Node();
              }
            } else {
              this.setState({
                toGo: this.state.toGo + 1,
                curstep: this.state.curstep + 1,
              });
            }
          }
        } else if (this.check_CQ(this.state.node)) {
          this.setState({
            toGo: this.state.toGo + 2,
            curstep: this.state.curstep + 1,
          });
        } else {
          this.reg_Node();
        }
      } else if (this.state.curstep == 2) {
        if (this.check_CQ(this.state.node)) {
          if (feeName === "Free Agent" || feeName === "Find Team") {
            if (
              this.state.node.Waivers != undefined &&
              this.state.node.Waivers.length > 0
            ) {
              this.setState({
                toGo: 4,
                curstep: this.state.totsteps,
                toWaiver: this.state.toWaiver + 1,
              });
            } else {
              this.reg_Node();
            }
          } else {
            this.setState({
              toGo: this.state.toGo + 1,
              curstep: this.state.curstep + 1,
            });
          }
        } else this.reg_Node();
      } else if (this.state.curstep == 3) {
        this.reg_Node();
      }
    } else {
      let temperrs = {};
      let rqrt4 = ssm_valid.ck_cqrequired(
        this.cqrequireds,
        this.state.customquestions,
        temperrs
      );
      if (!rqrt4) {
        this.setState({ cqerrors: temperrs });
        return;
      }

      if (this.checkfees()) {
        if (this.state.regdetail.fee == undefined) {
          this.setState({ feeError: "Required" });
          this.errors["Fee"] = "Required";
          return;
        } else {
          if (this.state.regdetail.fee.FId == 0) {
            this.setState({ feeError: "Required" });
            this.errors["Fee"] = "Required";
            return;
          }
          this.setState({ feeError: "" });
        }
      }

      let feeName = getfeeName(this.state.node, this.state.regdetail.fee?.FId);

      if (feeName === "Find Team" && this.state.selectedTeamID === 0) {
        this.errors["Teams"] = "Required";
        return;
      } else {
        this.errors["Teams"] = "";
      }

      let isCheckChild =
        this.state.node.OType === Constant.TP_VENUE
          ? this.check_child2(this.state.selregperson.BDay)
          : this.check_child(this.state.node, this.state.selregperson.BDay);

      if (isCheckChild && this.state.curstep == 0) {
        this.setState({
          toGo: this.state.toGo + 1,
          curstep: this.state.curstep + 1,
          totsteps: this.state.totsteps + 1,
          inputsEnable: this.state.family.RelaInfos.length > 0 && true,
        });
        return;
      }

      const isCQAnswered = ssm_valid.checkIsCustomQuestionIsAnswered(
        this.cqrequireds,
        this.state.customquestions
      );

      if (!isCQAnswered && this.check_CQ(this.state.node)) {
        this.setState({
          toGo: this.state.toGo + 2,
          curstep: this.state.curstep + 1,
          totsteps: this.state.totsteps + 1,
        });
        return;
      }

      if (
        this.state.node.Waivers != undefined &&
        this.state.node.Waivers.length > 0
      ) {
        if (this.state.toWaiver < this.state.node.Waivers.length) {
          this.setState({ regtxt: "I Accept" });
          this.setState({ prevtxt: "I DO Not Accept" });
          this.setState({
            toGo: 4,
            curstep: this.state.totsteps,
            toWaiver: this.state.toWaiver + 1,
          });
        } else {
          this.reg_Node();
        }
      } else {
        this.reg_Node();
      }
    }
    this.buttonDOM.blur();
  }

  handlePrevClick() {
    let rqrt2 = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt2) {
      return;
    }
    let rqrt3 = ssm_valid.ck_allvalid(this.state.cqerrors);
    if (!rqrt3) {
      return;
    }

    let isCheckChild =
      this.state.node.OType === Constant.TP_VENUE
        ? this.check_child2(this.state.selregperson.BDay)
        : this.check_child(this.state.node, this.state.selregperson.BDay);

    let feeName = getfeeName(this.state.node, this.state.regdetail.fee?.FId);

    if (this.state.curstep <= this.state.totsteps - 1) {
      if (this.state.curstep <= this.state.totsteps - 1) {
        this.setState({ regtxt: "Continue" });
      } else {
        this.setState({ regtxt: "Submit" });
      }

      if (this.state.curstep == 0) {
        this.props.onNavigate(-1);
      } else if (this.state.curstep == 1) {
        if (isCheckChild)
          this.setState({
            toGo: this.state.toGo - 1,
            curstep: this.state.curstep - 1,
          });
        else if (this.check_group(this.state.node)) {
          if (feeName === "Free Agent" || feeName === "Find Team") {
            this.setState({
              toGo: 0,
              curstep: 0,
            });
          } else {
            this.setState({
              toGo: this.state.toGo - 2,
              curstep: this.state.curstep - 1,
            });
          }
        } else if (this.check_CQ(this.state.node)) {
          this.setState({
            toGo: this.state.toGo - 3,
            curstep: this.state.curstep - 1,
          });
        }
      } else if (this.state.curstep == 2) {
        if (this.check_group(this.state.node)) {
          if (feeName === "Free Agent" || feeName === "Find Team") {
            this.setState({
              toGo: 0,
              curstep: 0,
              totsteps: isCheckChild && this.state.totsteps - 1,
            });
          } else {
            this.setState({
              toGo: this.state.toGo - 1,
              curstep: this.state.curstep - 1,
            });
          }
        } else if (this.check_CQ(this.state.node)) {
          this.setState({
            toGo: this.state.toGo - 2,
            curstep: this.state.curstep - 1,
          });
        }
      } else if (this.state.curstep == 3) {
        if (this.check_CQ(this.state.node)) {
          this.setState({
            toGo: this.state.toGo - 1,
            curstep: this.state.curstep - 1,
          });
        }
      }
    } else {
      this.setState({ regtxt: "Submit" });
      this.setState({ prevtxt: "Prev" });
      if (this.state.toWaiver == 1) {
        this.setState({
          toGo: 0,
          curstep: 0,
          toWaiver: 0,
          regtxt: "Continue",
        });
        if (isCheckChild) {
          this.setState({ totsteps: this.state.totsteps - 1 });
        }
      } else {
        if (this.state.node.OType === Constant.TP_VENUE) {
          this.setState({
            toGo: this.state.toGo - 4,
          });
        } else {
          this.setState({
            toGo: this.state.toGo - 3,
          });
        }
        this.setState({
          curstep: this.state.curstep - 1,
          toWaiver: 0,
        });
      }
    }
    this.buttonDOM2.blur();
  }

  handleCountOkClick(count) {
    this.reg_NRItem(this.state.node, this.getNodefee(), count);
  }

  handlePriceOkClick(fee) {
    this.reg_NRItem(this.state.node, fee, 1);
  }

  handlePriceOkClick2(fee) {
    this.setState({ loading: true });
    this.state.regdetail.fee = fee;

    if (this.state.regdetail.fee === undefined) {
      this.errors["Fee"] = "Required";
    }

    if (fee?.FId == 0) {
      this.errors["Fee"] = "Required";
    } else {
      this.errors["Fee"] = "";
    }
    this.setState({ loading: false });
  }

  getTeamsLength() {
    const items = [];
    for (var i in this.state.node.Groups) {
      if (this.state.node.Groups[i].Type === 9) {
        items.push(
          <option value={this.state.node.Groups[i].GId}>
            {this.state.node.Groups[i].Name}
          </option>
        );
      }
    }
    return items.length;
  }

  handleTeamChange(teamID) {
    this.errors["Teams"] = "";
    this.setState({ selectedTeamID: teamID });
    this.checkCurrentMaxMembers(teamID.GId);
  }

  handlePrerOkClick(code) {
    this.state.node.NdPreq = code;
    this.setState({ node: this.state.node });
  }

  prepare_CQs() {
    var regQAs = [];
    if (this.state.node == null) return regQAs;
    for (var i in this.state.node.Questions) {
      if (this.state.node.Questions[i].Requ)
        this.cqrequireds.push(this.state.node.Questions[i].QId);

      var regQA = new Object();
      regQA.QId = this.state.node.Questions[i].QId;
      regQA.QBody = this.state.node.Questions[i].Body;
      if (this.state.node.Questions[i].Type == 1) {
        regQA.Type = Constant.QTP_QUESTXT;
        regQA.Answ = "";
      } else {
        regQA.Type = Constant.QTP_QUESMUL;
        regQA.AnswId = Constant.SSM_ROOTID;
        regQA.Answ = "";
        regQA.Fee = 0;
        regQA.FId = Constant.SSM_ROOTID;
      }
      regQAs.push(regQA);
    }
    return regQAs;
  }

  kick_NRItem(node) {
    if (node.NdPreq == 1) {
      return <Prerequisite node={node} onOkClick={this.handlePrerOkClick} />;
    } else {
      if (this.checkfees()) {
        return <Price node={node} onOkClick={this.handlePriceOkClick} />;
      } else {
        return <Count onOkClick={this.handleCountOkClick} />;
      }
    }
  }

  kick_RR(node, name) {
    if (node.NdPreq == 1) {
      return <Prerequisite node={node} onOkClick={this.handlePrerOkClick} />;
    } else {
      return this.getRegContent(node, name);
    }
  }

  get_regDetail() {
    return this.state.regdetail;
  }

  bd_MainPcd(person, group, bGroup, personaddr) {
    let obj = {};
    var node = this.state.node;
    switch (node.OType) {
      case Constant.TP_ITEM:
        obj = node.Item;
        break;
      case Constant.TP_SPORT:
      case Constant.TP_CLASS:
      case Constant.TP_EVENT:
        obj = node.Activity;
        break;
    }

    var pcd = {};

    if (!this.checkfees())
      pcd = this.bd_Pcd(
        person,
        group,
        bGroup,
        Constant.PCD_MAIN,
        this.getNodefee()?.FId,
        this.getNodefee()?.Amou,
        null,
        null,
        1
      );
    else
      pcd = this.bd_Pcd(
        person,
        group,
        bGroup,
        Constant.PCD_MAIN,
        this.state.regdetail.fee.FId,
        getfeeAmou(this.state.node, this.state.regdetail.fee.FId),
        null,
        null,
        1
      );
    return pcd;
  }

  checkfees() {
    var rt = false;
    var node = null;
    switch (this.state.node.OType) {
      case Constant.TP_ITEM:
        node = this.state.node.Item;
        break;
      case Constant.TP_VENUE:
        node = this.state.node.Venue;
        break;
      case Constant.TP_SPORT:
      case Constant.TP_CLASS:
      case Constant.TP_EVENT:
        node = this.state.node.Activity;
        break;
    }
    var t5 = (node.Type & 2) == 2;
    if (!t5) {
      if (this.state.node.Fees.length > 2) rt = true;
    }
    return rt;
  }

  getNodefee() {
    var node = null;
    switch (this.state.node.OType) {
      case Constant.TP_ITEM:
        node = this.state.node.Item;
        break;
      case Constant.TP_VENUE:
        node = this.state.node.Venue;
        break;
      case Constant.TP_SPORT:
      case Constant.TP_CLASS:
      case Constant.TP_EVENT:
        node = this.state.node.Activity;
        break;
    }
    var t5 = (node.Type & 2) == 2;
    if (t5) {
      var inout =
        this.props.orginfo.InZip.indexOf(this.state.selregperson.PCode) >= 0
          ? true
          : false;
      return this.getinoutfee(inout);
    } else {
      for (var i in this.state.node.Fees) {
        if (this.state.node.Fees[i].Type == 1) return this.state.node.Fees[i];
      }
    }
    return null;
  }

  getinoutfee(inout) {
    for (var i in this.state.node.Fees) {
      if (this.state.node.Fees[i].Type == 1) {
        if (inout) {
          if (this.state.node.Fees[i].Name.indexOf("IN Postal Code") >= 0) {
            return this.state.node.Fees[i];
          }
        } else {
          if (this.state.node.Fees[i].Name.indexOf("OUT Postal Code") >= 0) {
            return this.state.node.Fees[i];
          }
        }
      }
    }
    return null;
  }

  getVenueFeeAmout(fees, name) {
    for (var i in fees) {
      if (fees[i]?.Name == name) return fees[i].Amt;
    }
    return 0;
  }

  getfeeName(fid) {
    for (var i in this.state.node.Fees) {
      if (this.state.node.Fees[i]?.FId == fid)
        return this.state.node.Fees[i].Name;
    }
    return "";
  }

  bd_QaPcds(person, group, bGroup, regQAs) {
    var pcds = [];
    if (regQAs != null)
      for (var i in regQAs) {
        if (regQAs[i]?.Type == Constant.QTP_QUESMUL) {
          var pcd = this.bd_Pcd(
            person,
            group,
            bGroup,
            Constant.PCD_QA,
            regQAs[i]?.FId,
            regQAs[i]?.Fee,
            regQAs[i],
            null,
            1
          );
          pcds.push(pcd);
        }
      }
    return pcds;
  }

  bd_TeamPcds(grouppersons, group) {
    var pcds = [];
    var fid = null;
    var fee = null;

    for (var i in this.state.node.Fees) {
      if (this.state.node.Fees[i].Type == Constant.FEETP_ACOAC) {
        fid = this.state.node.Fees[i]?.FId;
        fee = this.state.node.Fees[i].Amou;
        break;
      }
    }

    var pcd = this.bd_Pcd(
      null,
      group,
      null,
      Constant.PCD_TEAMMEMB,
      fid,
      fee * group.MemberCount,
      null,
      null,
      1
    );
    pcds.push(pcd);
    return pcds;
  }

  ck_Late() {
    var now = new Date();

    var start = "";
    var end = "";
    if (
      this.state.node.OType == Constant.TP_SPORT ||
      this.state.node.OType == Constant.TP_CLASS ||
      this.state.node.OType == Constant.TP_EVENT
    ) {
      start = this.state.node.Activity.LRStart;
      end = this.state.node.Activity.LREnd;
    } else if (this.state.node.OType == Constant.TP_VENUE2) {
      start = this.state.node.Venue.LRStart;
      end = this.state.node.Venue.LREnd;
    } else if (this.state.node.OType == Constant.TP_ITEM) {
      start = this.state.node.Item.LRStart;
      end = this.state.node.Item.LREnd;
    }

    if (now >= new Date(start)) {
      return true;
    } else {
      return false;
    }
  }

  bd_LFPcd(person) {
    var pcd = null;
    var fid = null;
    var fee = null;
    if (this.ck_Late()) {
      for (var i in this.state.node.Fees) {
        if (this.state.node.Fees[i].Type == Constant.FEETP_LATE) {
          fid = this.state.node.Fees[i]?.FId;
          fee = this.state.node.Fees[i].Amou;
          if (fee == -1) return null;
          break;
        }
      }
      pcd = this.bd_Pcd(
        person,
        null,
        null,
        Constant.PCD_LATEFEE,
        fid,
        fee,
        null,
        null,
        1
      );
    }
    return pcd;
  }

  bd_Pcds(person, group, bGroup, regQAs, grouppersons, personaddr, gtype) {
    var pcds = [];
    var pcd = this.bd_MainPcd(person, group, bGroup, personaddr);
    pcds.push(pcd);

    var qapcds = this.bd_QaPcds(person, group, bGroup, regQAs);
    for (var i in qapcds) {
      pcds.push(qapcds[i]);
    }

    var lfpcd = this.bd_LFPcd(person);
    if (lfpcd != null) pcds.push(lfpcd);

    if (bGroup && gtype === 1) {
      var tmpcds = this.bd_TeamPcds(grouppersons, group);
      if (tmpcds.length > 0) {
        for (var i in tmpcds) {
          pcds.push(tmpcds[i]);
        }
      }
    }
    return pcds;
  }

  get_parentaddr() {
    var parentaddr = new Object();

    let currentPerson = this.state.isChildFlow
      ? this.state.selparent
      : this.state.selregperson;

    if (
      this.state.family.PersonInfo.Addrs === null ||
      this.state.family.PersonInfo.Addrs.length === 0
    ) {
      parentaddr.AId = 0;
    } else {
      parentaddr.AId = this.state.family.PersonInfo.Addrs[0].AId;
    }

    parentaddr.Addr1 = currentPerson.Addr;
    parentaddr.Addr2 = "";
    parentaddr.App = 0;
    parentaddr.CTime = this.state.family.PersonInfo.Person.CTime;
    parentaddr.CUserId = this.state.family.PersonInfo.Person.CUserId;
    parentaddr.City = currentPerson.City;
    parentaddr.Comp = null;
    parentaddr.Coun = null;
    parentaddr.Desc = null;
    parentaddr.Long = "";
    parentaddr.Lati = "";
    parentaddr.OId = this.state.family.PersonInfo.Person.PId;
    parentaddr.Otype = 0;
    parentaddr.OrgId = parseInt(this.orgid);
    parentaddr.PCode = currentPerson.PCode;
    parentaddr.Regi = currentPerson.Regi;
    parentaddr.Sys = 0;
    parentaddr.Type = 0;
    parentaddr.UTime = this.state.family.PersonInfo.Person.UTime;
    parentaddr.UUserId = this.state.family.PersonInfo.Person.UUserId;

    return parentaddr;
  }

  get_personaddr(person) {
    var personaddr = new Object();

    var selectedPersonInfo = null;

    const family = [
      ...this.state.family.RelaInfos,
      ...[this.state.family.PersonInfo],
    ];

    for (var i in family) {
      if (family[i].Person.PId == person.PId) {
        selectedPersonInfo = family[i];
        break;
      }
    }

    if (
      selectedPersonInfo?.Addrs[0]?.AId == null ||
      selectedPersonInfo?.Addrs.length == 0
    ) {
      personaddr.AId = 0;
    } else {
      personaddr.AId = selectedPersonInfo?.Addrs[0]?.AId;
    }

    personaddr.OrgId = parseInt(this.orgid);
    personaddr.Addr1 = this.state.selregperson.Addr;
    personaddr.Addr2 = "";
    personaddr.App = 0;
    personaddr.Otype = 0;
    personaddr.City = this.state.selregperson.City;
    personaddr.Regi = this.state.selregperson.Regi;
    personaddr.PCode = this.state.selregperson.PCode;
    personaddr.Long = "";
    personaddr.Lati = "";
    return personaddr;
  }

  get_personphones(person) {
    var personphones = [];
    var phone = {};
    phone.OId = person.PId;
    phone.Num = this.state.selregperson.Num;
    phone.PId = 0;
    phone.Type = 1;
    phone.Prov =
      this.state.selregperson.Gate == -1 ? 1 : this.state.selregperson.Gate;
    phone.Prio = 1;
    phone.Sms = this.state.selregperson.Sms;
    personphones.push(phone);
    return personphones;
  }

  get_parentphones(person) {
    var personphones = [];
    var phone = {};
    phone.OId = person.PId;
    phone.Num = this.state.selparent.Num;
    phone.PId = 0;
    phone.Type = 1;
    phone.Prov =
      this.state.selparent.Gate == -1 ? 1 : this.state.selparent.Gate;
    phone.Prio = 1;
    phone.Sms = this.state.selparent.Sms;
    personphones.push(phone);
    return personphones;
  }

  get_UnP_GId(node) {
    var gid = null;
    for (var i = 0; i < node.Groups.length; i++) {
      if (node.Groups[i].Type == Constant.TP_TEAM_UNP) {
        gid = node.Groups[i].GId;
        break;
      }
    }
    return gid;
  }

  getGroupID(node) {
    let feeName = getfeeName(this.state.node, this.state.regdetail.fee?.FId);
    if (feeName === "Find Team") {
      return this.state.selectedTeamID.GId;
    } else {
      return this.get_UnP_GId(node);
    }
  }

  getFeeGroupType() {
    let feeName = getfeeName(this.state.node, this.state.regdetail.fee?.FId);
    if (feeName === "Register Team") {
      return 1;
    } else if (feeName === "Find Team") {
      return 2;
    } else if (feeName === "Free Agent") {
      return 3;
    } else {
      return 0;
    }
  }

  bd_MainPcd5(person, group, bGroup, personaddr, vrinfo) {
    let isInZipCode = false;
    let pcd = null;
    let feeInZipCode = vrinfo.Dr.VFFees[0].Amt;
    let feeOutZipCode = vrinfo.Dr.VFFees[1].Amt;
    let feeid = Constant.SSM_ROOTID;
    this.props.orginfo.InZip.indexOf(this.state.selregperson.PCode) >= 0
      ? (isInZipCode = true)
      : (isInZipCode = false);

    pcd = this.bd_Pcd(
      person,
      group,
      bGroup,
      Constant.PCD_MAIN,
      feeid,
      isInZipCode ? feeInZipCode : feeOutZipCode,
      null,
      vrinfo,
      1
    );

    return pcd;
  }

  bd_MainPcd4(person, group, bGroup, personaddr, vrinfo) {
    let pcd;
    let fees = vrinfo.Dr.VFFees;

    if (vrinfo.Dr.VFFees.length == 1) {
      var feev = fees[0].Amt;
      var feeid = Constant.SSM_ROOTID;
      pcd = this.bd_Pcd(
        person,
        group,
        bGroup,
        Constant.PCD_MAIN,
        feeid,
        feev,
        null,
        vrinfo,
        1
      );
    } else {
      const feeID = this.state.regdetail.fee.FId;
      const currentVenueFeeName = this.getfeeName(feeID);
      const currentVenueFeeAmt = this.getVenueFeeAmout(
        fees,
        currentVenueFeeName
      );
      pcd = this.bd_Pcd(
        person,
        group,
        bGroup,
        Constant.PCD_MAIN,
        feeID,
        currentVenueFeeAmt,
        null,
        vrinfo,
        1
      );
    }
    return pcd;
  }

  bd_Pcds5(person, regQAs, personaddr, vrinfo) {
    var pcds = [];
    var pcd = this.bd_MainPcd5(person, null, false, personaddr, vrinfo);
    pcds.push(pcd);

    var qapcds = this.bd_QaPcds(person, null, false, regQAs);
    for (var i in qapcds) {
      pcds.push(qapcds[i]);
    }

    var lfpcd = this.bd_LFPcd(person);
    if (lfpcd != null) pcds.push(lfpcd);
    return pcds;
  }

  bd_Pcds4(person, regQAs, personaddr, vrinfo) {
    var pcds = [];
    var pcd = this.bd_MainPcd4(person, [], false, personaddr, vrinfo);
    pcds.push(pcd);

    var qapcds = this.bd_QaPcds(person, null, false, regQAs);
    for (var i in qapcds) {
      pcds.push(qapcds[i]);
    }

    var lfpcd = this.bd_LFPcd(person);
    if (lfpcd != null) pcds.push(lfpcd);
    return pcds;
  }

  reg_Node() {
    var node = this.state.node;
    var reg = {};
    reg.RId = "00000000-0000-0000-0000-000000000000";

    reg.OrgId = this.orgid;
    reg.OId = node.OId;
    reg.OType = node.OType;

    var person = this.state.selregperson;
    if (person.BDay == "NaN/NaN/NaN" || !person.BDay)
      person.BDay = "01/01/1970";
    person.Mobi = this.state.selregperson.Num;
    person.OrgId = this.orgid;
    person.Gate =
      this.state.selregperson.Gate == -1 ? 1 : this.state.selregperson.Gate;

    var personaddr = this.get_personaddr(person);

    var personphones =
      this.state.selregperson.Sms === 1
        ? this.get_personphones(this.state.family.PersonInfo.Person)
        : [];

    var regDetail = null;

    if (
      this.state.selparent.Addr === null ||
      this.state.selparent.Addr === undefined
    ) {
      this.state.selparent.Addr = person.Addr;
    }
    if (
      this.state.selparent.City === null ||
      this.state.selparent.City === undefined
    ) {
      this.state.selparent.City = person.City;
    }
    if (
      this.state.selparent.Regi === null ||
      this.state.selparent.Regi === undefined
    ) {
      this.state.selparent.Regi = person.Regi;
    }
    if (
      this.state.selparent.PCode === null ||
      this.state.selparent.PCode === undefined
    ) {
      this.state.selparent.PCode = person.PCode;
    }

    if (!this.state.selparent.BDay) {
      this.state.selparent.BDay = "01/01/1970";
    }

    if (
      this.state.selparent.Gend === null ||
      this.state.selparent.Gend === undefined
    ) {
      this.state.selparent.Gend = person.Gend;
    }

    if (
      this.state.selparent.Mobi === null ||
      this.state.selparent.Mobi === undefined
    ) {
      this.state.selparent.Mobi = person.Num;
    }

    if (
      this.state.selparent.Num === null ||
      this.state.selparent.Num === undefined
    ) {
      this.state.selparent.Num = person.Num;
    }

    if (
      this.state.selparent.BDay === "NaN/NaN/NaN" ||
      this.state.selparent.BDay === undefined
    ) {
      this.state.selparent.BDay = person.BDay;
    }

    this.state.selparent.UUserdId = this.state.family.PersonInfo.Person.PId;

    var parent = null;

    if (this.state.node.Common.ABirt !== 1) {
      parent = this.state.selparent;
    }

    regDetail = this.get_regDetail();

    var regQAs = this.state.customquestions;
    var sportregistrationinfo = new Object();
    sportregistrationinfo.reg = reg;
    sportregistrationinfo.OrgId = this.orgid;
    sportregistrationinfo.OId = node.OId;
    if (node.OType == Constant.TP_VENUE)
      sportregistrationinfo.OType = node.OType + 300;
    else sportregistrationinfo.OType = node.OType + 100;
    sportregistrationinfo.TZId = this.props.orginfo.TZId;
    sportregistrationinfo.WSite = this.props.orginfo.WSite;

    if (person.PId == 0) {
      sportregistrationinfo.relationId =
        this.state.family.PersonInfo.Person.PId;
      sportregistrationinfo.relation = 5;
    } else {
      sportregistrationinfo.relationId = 0;
      sportregistrationinfo.relation = 1;
    }

    if (
      node.OType == Constant.TP_SPORT ||
      node.OType == Constant.TP_EVENT ||
      node.OType == Constant.TP_CLASS
    ) {
      var bGroup = this.check_group(this.state.node);
      var grouppersons = [];
      var group = null;
      if (bGroup) {
        group = new Object();
        group.Name = this.state.teamname;
        group.Type = Constant.TP_TEAM;
        group.MemberCount = this.state.players.length;
        group.GId = "00000000-0000-0000-0000-000000000000";
        grouppersons = this.state.groupPersons;
      }

      var t0 = (node.Activity.Type & 1) == 1;
      var gid = this.getGroupID(node);
      var gtype = this.getFeeGroupType();

      sportregistrationinfo.bTeam = t0;
      sportregistrationinfo.SGId = gid;
      sportregistrationinfo.SGType = gtype;
      if (gtype === 1) {
        sportregistrationinfo.group = group;
        sportregistrationinfo.grouppersons = grouppersons;
      }

      sportregistrationinfo.pcds = this.bd_Pcds(
        person,
        group ?? null,
        bGroup,
        regQAs,
        grouppersons ?? null,
        personaddr,
        gtype
      );
    } else if (node.OType == Constant.TP_VENUE) {
      for (var i in this.state.C_MulSlots) {
        if (node.Venue.Type == 2) {
          this.state.C_MulSlots[i].Pcds = this.bd_Pcds5(
            person,
            regQAs,
            personaddr,
            this.state.C_MulSlots[i]
          );
        } else {
          this.state.C_MulSlots[i].Pcds = this.bd_Pcds4(
            person,
            regQAs,
            personaddr,
            this.state.C_MulSlots[i]
          );
        }

        this.state.C_MulSlots[i].regQAs = regQAs;
        if (this.state.C_MulSlots[i].Type == Constant.PLAN_SLOT)
          this.state.C_MulSlots[i].Dr.ExVen_Rngs = [];
      }
      sportregistrationinfo.VRInfos = this.state.C_MulSlots;
    } else if (node.OType == Constant.TP_ITEM) {
      sportregistrationinfo.Type = node.Item.RType;
      sportregistrationinfo.TransType = node.Item.TransType;
      if (
        node.Item.TransType == 2 ||
        node.Item.TransType == 3 ||
        node.Item.TransType == 4
      )
        sportregistrationinfo.reg.PaId = 0;
      sportregistrationinfo.pcds = this.bd_Pcds(
        person,
        null,
        false,
        regQAs,
        null,
        personaddr,
        null
      );
    }

    sportregistrationinfo.person = person;
    sportregistrationinfo.personaddr = personaddr;
    sportregistrationinfo.personphones = personphones;

    sportregistrationinfo.NdPreq = 0;
    sportregistrationinfo.preRq = "";
    sportregistrationinfo.parent = parent;
    sportregistrationinfo.parentaddr = this.get_parentaddr();

    if (this.state.selparent.PId === this.state.selregperson.PId) {
      sportregistrationinfo.parentphones = personphones;
    } else {
      sportregistrationinfo.parentphones =
        this.state.selparent.Sms === 1
          ? this.get_parentphones(this.state.selparent)
          : [];
    }
    sportregistrationinfo.regDetail = regDetail;
    sportregistrationinfo.pcds = sportregistrationinfo?.pcds
      ? sportregistrationinfo?.pcds?.filter((pcd) => pcd !== null)
      : sportregistrationinfo?.pcds;
    sportregistrationinfo.regQAs = regQAs;
    this.regdo_Node_m(sportregistrationinfo);
  }

  reg_NRItem(node, fee, count) {
    var c_Orgid = this.orgid;
    var reg = new Object();
    reg.OrgId = c_Orgid;
    reg.OId = node.OId;
    reg.OType = node.OType;

    var sportregistrationinfo = new Object();
    sportregistrationinfo.Type = node.Item.RType;
    sportregistrationinfo.reg = reg;
    sportregistrationinfo.OrgId = c_Orgid;
    sportregistrationinfo.OId = node.OId;
    sportregistrationinfo.OType = node.OType + 100;
    sportregistrationinfo.TZId = this.props.orginfo.TZId;
    sportregistrationinfo.TransType = node.Item.TransType;
    if (
      node.Item.TransType == 2 ||
      node.Item.TransType == 3 ||
      node.Item.TransType == 4
    )
      sportregistrationinfo.reg.PaId = this.state.family.PersonInfo.Person.PId;
    sportregistrationinfo.person = this.state.family.PersonInfo.Person;

    sportregistrationinfo.pcds = this.bd_Pcds3(fee, count);
    sportregistrationinfo.regQAs = [];
    this.regdo_Node_m(sportregistrationinfo);
  }

  g_LNName(node) {
    let list = [];
    this.f_AllParItems_m(list, node.OId, 0);
    let nodeName = "";
    switch (node.OType) {
      case Constant.TP_VENUE:
        nodeName = node.Venue.Name;
        break;
      case Constant.TP_ITEM:
        nodeName = node.Item.Name;
        break;
      case Constant.TP_SPORT:
        nodeName = node.Activity.Name;
      case Constant.TP_CLASS:
        nodeName = node.Activity.Name;
      case Constant.TP_EVENT:
        nodeName = node.Activity.Name;
        break;
    }

    let lname = "";
    for (let i = list.length - 1; i >= 0; i--) {
      lname += list[i].Name + "/";
    }
    return lname + nodeName;
  }

  f_AllParItems_m(subits, oid, flag) {
    let simTree = this.props.smalltree;
    var node = this.g_Node_m(oid, simTree);
    for (var i in simTree) {
      if (simTree[i].OId == node?.PId) {
        if (flag == 1) {
          if ((simTree[i].App & Constant.APP_ARCHIVED) == 32) {
            subits.push(simTree[i]);
          }
        } else {
          subits.push(simTree[i]);
        }
        if (simTree[i]?.PId == Constant.SSM_ROOTID) return;
        this.f_AllParItems_m(subits, simTree[i].OId);
      }
    }
  }

  g_Node_m(id, arr) {
    var node = null;
    if (arr != null && arr.length > 0) {
      for (var m in arr) {
        if (arr[m].OId == id) {
          node = arr[m];
          break;
        }
      }
    }
    return node;
  }

  bd_Pcd(person, group, bGroup, type, fid, fee, qa, vrinfo, count) {
    var OId = this.state.node.OId;
    var OType = this.state.node.OType;
    var node = null;
    switch (this.state.node.OType) {
      case Constant.TP_ITEM:
        node = this.state.node.Item;
        break;
      case Constant.TP_VENUE:
        node = this.state.node.Venue;
        break;
      case Constant.TP_SPORT:
      case Constant.TP_CLASS:
      case Constant.TP_EVENT:
        node = this.state.node.Activity;
        break;
    }

    var pcd = null;
    if (type === Constant.PCD_QA && qa?.Answ === -1) {
      return pcd;
    }

    pcd = new Object();
    pcd.PPCDId = Constant.SSM_ROOTID;
    pcd.OId = OId;
    pcd.OType = OType;
    if (OType == Constant.TP_SPORT) pcd.PType = Constant.TP_PLAYER;
    else if (OType == Constant.TP_CLASS) pcd.PType = Constant.TP_STUDENT;
    else if (OType == Constant.TP_EVENT) pcd.PType = Constant.TP_PARTICIPATOR;
    else if (OType == Constant.TP_VENUE || pcd.OType == Constant.TP_VENUE3)
      pcd.PType = Constant.TP_SUBSCRIBER;
    else if (OType == Constant.TP_ITEM) pcd.PType = Constant.TP_PURCHASER;
    pcd.LId = Constant.SSM_ROOTID;

    pcd.Type = type;
    if (type == Constant.PCD_MAIN) {
      if (person != null) {
        if (OType != Constant.TP_VENUE) {
          if (bGroup == false)
            pcd.Desc =
              this.g_LNName(this.state.node) + " (" + person.FName + ")";
          else
            pcd.Desc =
              this.g_LNName(this.state.node) + " (Team : " + group.Name + ")";
        } else {
          if (node.VType == 2) {
            var name = "";

            pcd.OId = vrinfo.OId;
            name = vrinfo.NdName;

            pcd.Desc =
              name +
              " " +
              formatCompleteDateENGB(vrinfo.Event.start) +
              ",Check In:" +
              vrinfo.Dr.Day_Rngs[0].Start +
              ",Check Out:" +
              vrinfo.Dr.Day_Rngs[0].End +
              " (" +
              person.FName +
              ")";
          } else {
            pcd.Desc =
              this.g_LNName(this.state.node) +
              " " +
              formatCompleteDateENGB(vrinfo.Event.start) +
              " " +
              formatDateEN(vrinfo.Event.start) +
              " - " +
              formatCompleteDateENGB(vrinfo.Event.end) +
              " " +
              formatDateEN(vrinfo.Event.end) +
              " (" +
              person.FName +
              ")";
          }
        }
      } else {
        pcd.Desc = this.g_LNName(this.state.node);
      }
    } else if (type == Constant.PCD_QA) {
      pcd.Desc = qa.QBody + " - " + qa?.Answ;
    } else if (type == Constant.PCD_LATEFEE) {
      pcd.Desc = "Late Registration";
    } else if (type == Constant.PCD_TEAMMEMB) {
      pcd.Desc = "Member count of " + group.MemberCount;
    } else if (type == Constant.PCD_EXRESV) {
      pcd.Desc = "Extra " + fid + " hour(s) reservation";
    }

    pcd.FId = fid;
    pcd.Rate = (fee==-1)?0:fee;
    pcd.Count = count;
    pcd.INVId = this.state.invid;

    return pcd;
  }

  bd_MainPcd3(fee, count) {
    var pcd = this.bd_Pcd(
      null,
      null,
      false,
      Constant.PCD_MAIN,
      fee.FId,
      getfeeAmou(this.state.node, fee.FId),
      null,
      null,
      count
    );
    return pcd;
  }

  bd_Pcds3(fee, count) {
    var pcds = [];
    var pcd = this.bd_MainPcd3(fee, count);
    pcds.push(pcd);

    return pcds;
  }

  async regdo_Node_m(sportcreateinfo) {
    this.setState({ loading: true });
    const response = await registerNode(sportcreateinfo);

    if (response.Id == 0) {
      var obj = JSON.parse(response.Data);
      this.setState({ loading: false });
      this.props.onSubmitClick(
        obj.pcds,
        this.state.selregperson,
        sportcreateinfo.relation,
        obj.PId
      );
    } else {
      this.setState({ loading: false, errormsg: response.Description });
    }
  }

  check_CQ(node) {
    if (node.Questions == undefined) return false;
    if (node.Questions.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  g_Gate_m(node, m) {
    return parseInt(node.Activity.Gate.toString().substr(m, 1));
  }

  check_child(node, day) {
    var bChild = false;
    if (
      node.OType == Constant.TP_SPORT ||
      node.OType == Constant.TP_CLASS ||
      node.OType == Constant.TP_EVENT
    ) {
      var gatev = this.g_Gate_m(node, 0);
      switch (gatev) {
        case 1:
        case 2:
          if (day == "") return bChild;

          var date_b = new Date(day);
          var date_n = new Date(Date());
          var os = (date_n - date_b) / 3600000 / 24 / 365;

          bChild = os < 18;
          break;
        case 3:
          bChild = true;
          break;
      }
    } else {
      bChild = os < 18;
    }

    return bChild;
  }

  check_child2(day) {
    var bChild = false;
    if (day == "") return bChild;

    var date_b = new Date(day);
    var date_n = new Date(Date());
    var os = (date_n - date_b) / 3600000 / 24 / 365;
    bChild = os < 18;
    return bChild;
  }

  check_group(node) {
    var bGroup = false;

    switch (node.OType) {
      case Constant.TP_ITEM:
      case Constant.TP_VENUE:
        break;
      case Constant.TP_SPORT:
      case Constant.TP_CLASS:
      case Constant.TP_EVENT:
        var t0 = (node.Activity.Type & 1) == 1;
        var t1 = (node.Activity.Type & 4) == 4;
        var t2 = (node.Activity.Type & 8) == 8;
        var t3 = (node.Activity.Type & 16) == 16;
        var tp = 1; 

        if (!t0) {
          bGroup = false;
        } else if (t1 && !t2 && !t3) {
          bGroup = true;
        } else if (!t1 && t2 && !t3) {
          bGroup = false;
        } else if (!t1 && !t2 && t3) {
          bGroup = false;
        } else if (t1 && t2 && !t3) {
          bGroup = tp == 1;
        } else if (!t1 && t2 && t3) {
          bGroup = false;
        } else if (t1 && !t2 && t3) {
          bGroup = tp == 1;
        } else if (t1 && t2 && t3) {
          bGroup = tp == 1;
        }

        break;
    }

    return bGroup;
  }

  set_TotStep(node, person) {
    var i = 1;
    if (this.check_group(node)) {
      i++;
    }
    if (this.check_CQ(node)) {
      i++;
    }
    this.setState({ totsteps: i });
  }

  getRegContent(node, name) {
    let lnode;
    let registerTitle;
    switch (node.OType) {
      case Constant.TP_ITEM:
        lnode = node.Item;
        registerTitle = "Register";
        break;
      case Constant.TP_VENUE:
        lnode = node.Venue;
        registerTitle = "Reserve";
        break;
      case Constant.TP_SPORT:
        registerTitle = "Register";
        break;
      case Constant.TP_CLASS:
        registerTitle = "Register";
        break;
      case Constant.TP_EVENT:
        registerTitle = "Register";
        lnode = node.Activity;
        break;
    }
    switch (this.state.toGo) {
      case 0:
        node.OType == Constant.TP_VENUE
          ? (this.regsteptitle = "Registrant Info")
          : (this.regsteptitle = "Participant Info");
        break;
      case 1:
        this.regsteptitle = "Guardian Info";
        break;
      case 2:
        this.regsteptitle = "Team Info";
        break;
      case 3:
        this.regsteptitle = "Custom Question";
        break;
    }

    return (
      <div>
        <div className="content-area">
          <div className="section-title-container">
            <div className="ml-3 mr-2 pt-2">
              <BackTitle
                title={registerTitle + " " + name}
                onBack={() => {
                  this.state.curstep === 0 && this.state.toGo === 0
                    ? this.props.onNavigate(`/node#${this.props.node.OId}`)
                    : this.handlePrevClick();
                }}
              />
            </div>
          </div>
          <section id={!this.props.isDesktop && "cart-container"}>
            <div
              className={this.props.isDesktop ? "container" : "container-fluid"}
            >
              <div id="registration-step-bar">
                <div className="col-12">
                  {this.state.toGo == 4 ? (
                    <p className="p-steps">
                      Waiver {this.state.toWaiver} of{" "}
                      {this.state.node.Waivers.length}
                    </p>
                  ) : (
                    <p className="p-steps">
                      Step {this.state.curstep + 1} of {this.state.totsteps} :{" "}
                      {this.regsteptitle}
                    </p>
                  )}
                </div>
              </div>
              <div className={this.props.isDesktop && "ml-5 mr-5"}>
                {this.getSteps(this.state.toGo, this.state.toWaiver)}
              </div>
              {!this.state.selregpersoned ||
              (getfeeName(this.state.node, this.state.regdetail.fee?.FId) ===
                "Find Team" &&
                this.getTeamsLength() === 0) ? (
                <div></div>
              ) : (
                <div className={this.props.isDesktop && "ml-5 mr-5"}>
                  <div className="form-actions-area">
                    <button
                      onClick={this.handleSubmitClick}
                      ref={(buttonDOM) => {
                        this.buttonDOM = buttonDOM;
                      }}
                      className="filled-button-continue"
                    >
                      {this.state.regtxt}
                    </button>
                    <button
                      onClick={this.handlePrevClick}
                      ref={(buttonDOM2) => {
                        this.buttonDOM2 = buttonDOM2;
                      }}
                      className="border-button"
                    >
                      {this.state.prevtxt}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    );
  }

  getTeamMembers() {
    const membersCount = [];
    for (var i = 1; i <= this.state.node.Activity.MaxGMemb; i++) {
      membersCount.push(<option value={i}>{i}</option>);
    }
    return membersCount;
  }

  getMainContent(node, name) {
    switch (this.state.node.OType) {
      case Constant.TP_ITEM:
        if (node.RType == Constant.REGTP_NRITEM) {
          return this.kick_NRItem(this.state.node);
        } else {
          return this.kick_RR(this.state.node, name);
        }
        break;
      case Constant.TP_SPORT:
      case Constant.TP_CLASS:
      case Constant.TP_EVENT:
      case Constant.TP_VENUE:
        return this.kick_RR(this.state.node, name);
        break;
    }
  }

  f_famperson(pid, userinfo) {
    if (pid == userinfo.PersonInfo.Person.PId)
      return userinfo.PersonInfo.Person;

    for (var i in userinfo.RelaInfos) {
      if (userinfo.RelaInfos[i].Person.PId == pid) {
        return userinfo.RelaInfos[i].Person;
      }
    }
  }

  async checkCurrentMaxMembers(id) {
    const response = await checkMaxTeamMembers(id);
    if (response.Data >= this.state.node.Activity.MaxGMemb) {
      this.setState({ maxMembersReached: true });
    } else {
      this.setState({ maxMembersReached: false });
    }
  }

  async check_reged(id) {
    const response = await checkRegister(id, this.state.node.OId);
    if (response.Id == 0) {
      var p = this.f_famperson(id, this.state.family);
      p.BDay = formatDefaultBDay(p.BDay);

      const currentFamilyMember = this.state.family.RelaInfos.find(
        (element) => element.Person.PId === p.PId
      );

      if (
        currentFamilyMember &&
        currentFamilyMember.Phones &&
        currentFamilyMember.Phones[0]
      ) {
        p.Sms = currentFamilyMember.Phones[0].Sms ?? "";
      }
      this.errors = {};

      if (!check_gend(p.Gend, this.state.node)) {
        this.errors["Gend"] =
          "Your gender is not in the scope of this Activity.";
      } else {
        this.errors["Gend"] = "";
      }

      if (!this.check_age(p.BDay) && this.state.toGo !== 1) {
        this.errors["BDay"] = "Your age is not in the scope of this Activity.";
      }

      this.setState({ selregperson: p });
      this.set_TotStep(this.state.node, p);
      this.state.regdetail.ECon = p.EName;
      this.state.regdetail.EConP = p.ENumber;

      if (this.state.pcds.length === 0) {
        this.setState({ selectError: false });
        this.setState({ selregpersoned: true });
      } else {
        const activityPerson = {};

        for (let i = 0; i < this.state.pcds.length; i++) {
          const people = this.state.pcds[i];
          const activity = this.state.pcds[i];
          activityPerson[activity.OId + people.PId] = true;
        }

        const isInArray =
          activityPerson[this.state.node.OId + this.state.selregperson.PId];

        if (isInArray) {
          if (
            this.state.node.OType == Constant.TP_VENUE ||
            this.state.node.OType == Constant.TP_ITEM
          ) {
            this.setState({ selectError: false });
            this.setState({ selregpersoned: true });
          } else {
            this.setState({ selectError: true });
            this.setState({ selregpersoned: false });
          }
        } else {
          this.setState({ selectError: false });
          this.setState({ selregpersoned: true });
        }
      }

      if (!this.check_child2(this.state.family.PersonInfo.Person.BDay)) {
        this.state.family.PersonInfo.Person.BDay = formatDefaultBDay(
          this.state.family.PersonInfo.Person.BDay
        );
        this.state.family.PersonInfo.Person.Sms =
          this.state.family.PersonInfo?.Phones[0]?.Sms ?? 0;
        this.setState({ selparent: this.state.family.PersonInfo.Person });
      }

      for (var i in this.state.family.RelaInfos) {
        if (!this.check_child2(this.state.family.RelaInfos[i]?.Person.BDay)) {
          this.state.family.RelaInfos[i].Person.BDay = formatDefaultBDay(
            this.state.family.RelaInfos[i].Person.BDay
          );
          if (
            this.state.family.RelaInfos[i]?.Phones[0]?.Sms &&
            this.state.family.RelaInfos?.Phones
          ) {
            this.state.family.RelaInfos[i].Person.Sms =
              this.state.family.RelaInfos?.Phones[0]?.Sms;
          }
          this.setState({ selparent: this.state.family.RelaInfos[i].Person });
        }
      }
      this.errors["RegSel"] = "";
    } else if (response.Id == 25) {
      var p = this.f_famperson(id, this.state.family);
      this.setState({ selregperson: p });
      this.errors["RegSel"] =
        "This person is already registered in this activity.";
      this.setState({ selregpersoned: false });
    } else if (response.Id == 5) {
      var err = new Object();
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    } else {
      var p = this.f_famperson(id, this.state.family);
      this.setState({ selregperson: p });
      this.errors["RegSel"] = response.Description;
      this.setState({ selregpersoned: false });
    }
  }

  handleRegChange(e) {
    if (e.target.value == 0) {
      this.setState({
        selregperson: this.defaultPerson,
        regdetail: this.defaultDetail,
        inputsEnable: false,
      });
      this.set_TotStep(this.state.node, this.defaultPerson);
      this.state.regdetail.ECon = this.defaultPerson.EName;
      this.state.regdetail.EConP = this.defaultPerson.ENumber;
      this.setState({ selregpersoned: true });
      this.setState({ selectError: false });
      if (!this.check_child2(this.state.family.PersonInfo?.Person.BDay)) {
        this.state.family.PersonInfo.Person.BDay = formatDefaultBDay(
          this.state.family.PersonInfo.Person.BDay
        );
        this.setState({ selparent: this.defaultPerson });
      }
      for (var i in this.state.family.RelaInfos) {
        if (!this.check_child2(this.state.family?.RelaInfos[i].Person.BDay)) {
          this.state.family.RelaInfos[i].Person.BDay = formatDefaultBDay(
            this.state.family.RelaInfos[i].Person.BDay
          );
          this.setState({
            selparent: this.state.family.RelaInfos[i].Person,
          });
        }
      }
      this.errors["RegSel"] = "";
    } else {
      this.check_reged(e.target.value);
      this.setState({
        inputsEnable: true,
      });
    }
  }

  handleReg2Change(e) {
    if (e.target.value == 0) {
      this.setState({
        inputsEnable: false,
        selparent: this.defaultPerson,
      });
      this.errors["RegSel"] = "";
    } else {
      var p = this.f_famperson(e.target.value, this.state.family);
      p.BDay = formatDefaultBDay(p.BDay);
      this.setState({ selparent: p, selgardianed: true, inputsEnable: true });
    }
  }

  getRegsSel_v() {
    const items = [];
    items.push(
      <option value={this.state.family.PersonInfo.Person.PId}>
        {this.state.family.PersonInfo.Person.FName +
          " " +
          this.state.family.PersonInfo.Person.LName}
      </option>
    );
    for (var i in this.state.family.RelaInfos) {
      items.push(
        <option value={this.state.family.RelaInfos[i].Person.PId}>
          {this.state.family.RelaInfos[i].Person.FName +
            " " +
            this.state.family.RelaInfos[i].Person.LName}
        </option>
      );
    }
    items.push(<option value="0">Add new person</option>);

    return (
      <>
        <select
          className="form-control login-inputs"
          value={this.state.selregperson.PId}
          onChange={this.handleRegChange}
        >
          <option value="-1" selected disabled hidden>
            Select registrant
          </option>
          {items}
        </select>
        {this.state.selectError ? (
          <p style={{ color: "red" }}>
            The registration of this person for this activity is already in your
            shopping cart. Please select a different member or go to the
            shopping cart and finish the payment.
          </p>
        ) : null}
      </>
    );
  }

  getRegsSel2_v() {
    const items = [];
    if (!this.check_child2(this.state.family.PersonInfo.Person.BDay)) {
      items.push(
        <option selected value={this.state.family.PersonInfo.Person.PId}>
          {this.state.family.PersonInfo.Person.FName +
            " " +
            this.state.family.PersonInfo.Person.LName}
        </option>
      );
    }
    for (var i in this.state.family.RelaInfos) {
      if (!this.check_child2(this.state.family.RelaInfos[i].Person.BDay)) {
        items.push(
          <option value={this.state.family.RelaInfos[i].Person.PId}>
            {this.state.family.RelaInfos[i].Person.FName +
              " " +
              this.state.family.RelaInfos[i].Person.LName}
          </option>
        );
      }
    }

    this.state.adultsListLength = items.length;

    items.push(<option value="0">Add Adult to this family as Guardian</option>);

    return (
      <select
        className="form-control login-inputs"
        value={this.state.selparent.PId}
        onChange={this.handleReg2Change}
      >
        {items}
      </select>
    );
  }

  getState() {
    return (
      <select
        className="form-control login-inputs"
        type="text"
        name="Regi"
        value={this.state.selregperson.Regi}
        onChange={this.handleSelChange}
      >
        <option value="-1" selected>
          {this.state.node.Common.AState == 3
            ? "Select State*"
            : "Select State"}
        </option>
        <option value="AK">Alaska</option>
        <option value="AL">Alabama</option>
        <option value="AR">Arkansas</option>
        <option value="AZ">Arizona</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DC">District of Columbia</option>
        <option value="DE">Delaware</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="IA">Iowa</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="MA">Massachusetts</option>
        <option value="MD">Marlyand</option>
        <option value="ME">Maine</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MO">Missouri</option>
        <option value="MS">Mississippi</option>
        <option value="MT">Montana</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NE">Nebraska</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NV">Nevada</option>
        <option value="NY">New York</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VA">Virginia</option>
        <option value="VT">Vermont</option>
        <option value="WA">Washington</option>
        <option value="WI">Wisconsin</option>
        <option value="WV">West Virginia</option>
        <option value="WY">Wyoming</option>
      </select>
    );
  }

  getGrade() {
    return (
      <select
        name="Grad"
        className="form-control login-inputs"
        value={this.state.regdetail.Grad}
        onChange={this.handleSelChange2}
      >
        <option value="-1" selected>
          {this.state.node.Common.AGrad == 3 ? "Select Grade*" : "Select Grade"}
        </option>
        <option value="1">Pre School</option>
        <option value="2">Pre Kindergarten</option>
        <option value="3">Kindergarten</option>
        <option value="4">1st Grade</option>
        <option value="5">2nd Grade</option>
        <option value="6">3rd Grade</option>
        <option value="7">4th Grade</option>
        <option value="8">5th Grade</option>
        <option value="9">6th Grade</option>
        <option value="10">7th Grade</option>
        <option value="11">8th Grade</option>
        <option value="12">9th Grade</option>
        <option value="13">10th Grade</option>
        <option value="14">11th Grade</option>
        <option value="15">12th Grade</option>
        <option value="16">Post High School</option>
      </select>
    );
  }

  getSchool() {
    const items = [];
    for (var i in this.state.schools) {
      items.push(
        <option value={this.state.schools[i].SId}>
          {this.state.schools[i].Name}
        </option>
      );
    }
    return (
      <select
        name="Scho"
        className="form-control login-inputs"
        value={this.state.regdetail.Scho}
        onChange={this.handleSelChange2}
      >
        <option value="11111111-1111-1111-1111-111111111111" selected>
          {this.state.node.Common.AScho == 3
            ? "Select School*"
            : "Select School"}
        </option>
        {items}
      </select>
    );
  }

  getCarrierOptions() {
    return (
      <>
        {" "}
        <option value="-1" selected="selected">
          Select Carrier*
        </option>
        <option value="5">Alltel</option>
        <option value="17">Ameritech</option>
        <option value="25">AT&amp;T Wireless</option>
        <option value="1">Bellsouth</option>
        <option value="15">Boost Mobile</option>
        <option value="9">CellularOne</option>
        <option value="8">CellularOne MMS</option>
        <option value="2">Cingular</option>
        <option value="22">Cricket</option>
        <option value="21">Edge Wireless</option>
        <option value="12">Globalstar (satellite)</option>
        <option value="3">GoPhone (Cingular)</option>
        <option value="28">Inland Cellular</option>
        <option value="13">Iridium (satellite)</option>
        <option value="16">Metro PCS</option>
        <option value="6">Nextel</option>
        <option value="10">O2</option>
        <option value="11">Orange</option>
        <option value="19">Qwest</option>
        <option value="18">Rogers Wireless</option>
        <option value="7">Sprint PCS</option>
        <option value="24">T-Mobile</option>
        <option value="23">Teleflip</option>
        <option value="14">Telus Mobility</option>
        <option value="4">US Cellular</option>
        <option value="27">Verizon</option>
        <option value="26">Virgin Mobile</option>
        <option value="29">Straight Talk</option>
        <option value="30">Consumer Cellular</option>
      </>
    );
  }

  getCarrier() {
    return (
      <select
        name="Gate"
        className="form-control login-inputs"
        value={this.state.selregperson.Gate}
        onChange={this.handleSelChange3}
      >
        {this.getCarrierOptions()}
      </select>
    );
  }

  getCarrierStepTwo() {
    return (
      <select
        name="Gate"
        className="form-control login-inputs"
        value={this.state.selparent.Gate}
        onChange={this.handleSelChange4}
      >
        {this.getCarrierOptions()}
      </select>
    );
  }

  //Members logic
  toggle() {
    this.setState({
      teamMember: {
        FName: "",
        LName: "",
        Emai: "",
        PId: 0,
        RId: "00000000-0000-0000-0000-000000000000",
        Num: "",
        Gend: "1",
        BDay: "01/01/1970",
        Grad: "0",
        Disa: false,
        Type: 10,
      },
    });
    if (this.state.players.length === this.state.node?.Activity.MaxGMemb) {
      this.setState({
        modalError: `The max team member is limited to ${this.state.node.Activity.MaxGMemb}`,
      });
    } else {
      this.setState({ modalError: "" });
    }
    this.setState({ modal: !this.state.modal, isExistingEmail: false });
  }

  handleMemberChange = async (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;

    if (this.state.teamMember.Emai !== undefined) {
      if (name === "Emai") {
        this.checkEmail(val);
      }
    }

    this.setState((prevState) => ({
      teamMember: {
        ...prevState.teamMember,
        [name]: val,
      },
      modalError: "",
    }));
  };

  handleGroupCheckboxChange = (event) => {
    let name = event.target.id;

    this.setState((prevState) => ({
      groupChecks: {
        ...prevState.groupChecks,
        [name]: !prevState.groupChecks[name],
      },
    }));
  };

  handleMemberTypeRadio = (event) => {
    let value = event.target.value;

    this.setState((prevState) => ({
      teamMember: {
        ...prevState.teamMember,
        Type: parseInt(value),
      },
    }));
  };

  handleMemberGenderRadio = (event) => {
    let value = event.target.value;

    this.setState((prevState) => ({
      teamMember: {
        ...prevState.teamMember,
        Gend: value,
      },
    }));
  };

  handleMemberDisaRadio = (event) => {
    let value = event.target.value;

    this.setState((prevState) => ({
      teamMember: {
        ...prevState.teamMember,
        Disa: value === "1" ? true : false,
      },
    }));
  };

  handleAddNewMember = () => {
    if (
      this.state.node?.Activity.MaxGMemb !== 0 &&
      this.state.players.length === this.state.node?.Activity.MaxGMemb &&
      this.state.teamMember.Type === 10
    ) {
      this.setState({
        modalError: `The max team member is limited to ${this.state.node.Activity.MaxGMemb}`,
      });
      return;
    } else {
      this.setState({ modalError: "" });
    }

    if (
      this.state.teamMember.FName === "" ||
      !this.state.teamMember.LName === "" ||
      !this.state.teamMember.Emai === "" ||
      !this.state.teamMember.Num === ""
    ) {
      this.setState({ modalError: "Fill in the information of all fields" });
      return;
    } else {
      this.setState({ modalError: "" });
    }

    const newMember = {
      OrgId: this.orgid,
      FName: this.state.teamMember.FName,
      LName: this.state.teamMember.LName,
      Emai: this.state.teamMember.Emai,
      PId: this.state.teamMember.PId,
      RId: this.state.teamMember.RId,
      Num: this.state.teamMember.Num,
      Gend: this.state.teamMember.Gend,
      BDay: this.state.teamMember.BDay,
      Grad: this.state.teamMember.Grad,
      Disa: this.state.teamMember.Disa,
      Type: this.state.teamMember.Type,
    };

    if (this.state.teamMember.Type === 10) {
      this.setState({ players: [...this.state.players, newMember] });
    } else {
      this.setState({ coachs: [...this.state.coachs, newMember] });
    }

    this.setState({
      groupPersons: [...this.state.groupPersons, newMember],
      isExistingEmail: false,
    });
    this.toggle();
  };

  getSteps(step, waiver) {
    switch (step) {
      case 0:
        return (
          <>
            <div id="step-one" className="row">
              <div className="col-12">
                <form>
                  {this.getRegsSel_v()}
                  {ssm_valid.showerror("RegSel", this.errors)}
                  {this.state.selregpersoned && (
                    <div>
                      <input
                        className="form-control login-inputs"
                        type="text"
                        name="Emai"
                        maxLength={50}
                        placeholder="Email*"
                        value={this.state.selregperson.Emai}
                        onChange={this.handleChange}
                        disabled={this.state.inputsEnable}
                      />
                      {ssm_valid.showerror("Emai", this.errors)}
                      <input
                        className="form-control login-inputs"
                        type="text"
                        maxLength={64}
                        name="FName"
                        placeholder="First Name*"
                        value={this.state.selregperson.FName}
                        onChange={this.handleChange}
                        disabled={this.state.inputsEnable}
                      />
                      {ssm_valid.showerror("FName", this.errors)}
                      <input
                        className="form-control login-inputs"
                        type="text"
                        maxLength={64}
                        name="LName"
                        placeholder="Last Name*"
                        value={this.state.selregperson.LName}
                        onChange={this.handleChange}
                        disabled={this.state.inputsEnable}
                      />
                      {ssm_valid.showerror("LName", this.errors)}
                      {this.state.node.Common.ABirt !== 1 && (
                        <div className="bdaybox">
                          <InputMask
                            className="form-control login-inputs"
                            mask="99/99/9999"
                            name="BDay"
                            placeholder={
                              this.state.node.Common.ABirt == 3
                                ? "Date of Birth*"
                                : "Date of Birth"
                            }
                            value={this.state.selregperson.BDay}
                            onChange={this.handleChange}
                          />
                          {ssm_valid.showerror("BDay", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.AGend !== 1 && (
                        <div>
                          <Radiobtn
                            label1="Male"
                            label2="Female"
                            req={this.state.node.Common.AGend == 3 ? "*" : ""}
                            value={this.state.selregperson.Gend}
                            onRadioChange={this.handleRadioChange}
                          />
                          {ssm_valid.showerror("Gend", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.AAddr !== 1 && (
                        <div>
                          <input
                            className="form-control login-inputs"
                            type="text"
                            name="Addr"
                            placeholder={
                              this.state.node.Common.AAddr == 3
                                ? "Street*"
                                : "Street"
                            }
                            value={this.state.selregperson.Addr}
                            onChange={this.handleChange}
                            maxLength={50}
                          />
                          {ssm_valid.showerror("Addr", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.ACity !== 1 && (
                        <div>
                          <input
                            className="form-control login-inputs"
                            type="text"
                            name="City"
                            placeholder={
                              this.state.node.Common.ACity == 3
                                ? "City*"
                                : "City"
                            }
                            value={this.state.selregperson.City}
                            onChange={this.handleChange}
                            maxLength={64}
                          />
                          {ssm_valid.showerror("City", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.AState !== 1 && (
                        <div>
                          {this.getState()}
                          {ssm_valid.showerror("Regi", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.APCode !== 1 && (
                        <div>
                          <InputMask
                            className="form-control login-inputs"
                            mask="99999"
                            type="text"
                            name="PCode"
                            placeholder={
                              this.state.node.Common.APCode == 3
                                ? "Zip Code*"
                                : "Zip Code"
                            }
                            value={this.state.selregperson.PCode}
                            onChange={this.handleChange}
                          />
                          {ssm_valid.showerror("PCode", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.APhon !== 1 && (
                        <div>
                          <InputMask
                            className="form-control login-inputs"
                            mask="(999)999-9999"
                            type="text"
                            name="Num"
                            placeholder={
                              this.state.node.Common.APhon == 3
                                ? "Phone*"
                                : "Phone"
                            }
                            value={this.state.selregperson.Num}
                            onChange={this.handleChange}
                          />
                          {ssm_valid.showerror("Num", this.errors)}
                          <Checkbox
                            id="Sms"
                            label="Allow text messages"
                            isSelected={this.state.selregperson.Sms === 1}
                            onCheckboxChange={this.handleCheckboxChange}
                            key="Sms"
                          />
                          {this.state.selregperson.Sms === 1 && (
                            <div>
                              {this.getCarrier()}
                              {ssm_valid.showerror("Gate", this.errors)}
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.node.Common.AECont !== 1 && (
                        <div>
                          <input
                            className="form-control login-inputs"
                            type="text"
                            name="ECon"
                            maxLength={100}
                            placeholder={
                              this.state.node.Common.AECont == 3
                                ? "Emergency Contact*"
                                : "Emergency Contact"
                            }
                            value={this.state.regdetail.ECon}
                            onChange={this.handleChange2}
                          />
                          {ssm_valid.showerror("ECon", this.errors)}
                          <InputMask
                            className="form-control login-inputs"
                            mask="(999)999-9999"
                            type="text"
                            name="EConP"
                            placeholder={
                              this.state.node.Common.AECont == 3
                                ? "Emergency Phone*"
                                : "Emergency Phone"
                            }
                            value={this.state.regdetail.EConP}
                            onChange={this.handleChange2}
                          />
                          {ssm_valid.showerror("EConP", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.AGrad !== 1 && (
                        <div>
                          {this.getGrade()}
                          {ssm_valid.showerror("Grad", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.AScho !== 1 && (
                        <div>
                          {this.getSchool()}
                          {ssm_valid.showerror("Scho", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.AHeig !== 1 && (
                        <div>
                          <div className="row">
                            <div className="col-6 input-left">
                              <InputMask
                                className="form-control login-inputs"
                                mask="999"
                                type="text"
                                name="Heig"
                                placeholder={
                                  this.state.node.Common.AHeig == 3
                                    ? "Feet*"
                                    : "Feet"
                                }
                                value={this.state.regdetail.Heig}
                                onChange={this.handleChange2}
                              />
                            </div>
                            <div className="form-control col-6 input-right">
                              <InputMask
                                className="login-inputs"
                                mask="999"
                                type="text"
                                name="Heig2"
                                placeholder={
                                  this.state.node.Common.AHeig == 3
                                    ? "Inches*"
                                    : "Inches"
                                }
                                value={this.state.regdetail.Heig2}
                                onChange={this.handleChange2}
                              />
                            </div>
                          </div>
                          {ssm_valid.showerror("Heig", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.AWeig !== 1 && (
                        <div>
                          <InputMask
                            className="form-control login-inputs"
                            mask="999"
                            type="text"
                            name="Weig"
                            placeholder={
                              this.state.node.Common.AWeig == 3
                                ? "Weight*"
                                : "Weight"
                            }
                            value={this.state.regdetail.Weig}
                            onChange={this.handleChange2}
                          />
                          {ssm_valid.showerror("Weig", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.ABudd !== 1 && (
                        <div>
                          <input
                            className="form-control login-inputs"
                            type="text"
                            name="Budd"
                            maxLength={32}
                            placeholder={
                              this.state.node.Common.ABudd == 3
                                ? "Buddy*"
                                : "Buddy"
                            }
                            value={this.state.regdetail.Budd}
                            onChange={this.handleChange2}
                          />
                          {ssm_valid.showerror("Budd", this.errors)}
                        </div>
                      )}
                      {this.state.node.Common.APref !== 1 && (
                        <div className="precbox">
                          <input
                            className="form-control login-inputs"
                            type="text"
                            name="PCoa"
                            maxLength={32}
                            placeholder={
                              this.state.node.Common.APref == 3
                                ? "Prefer Coach*"
                                : "Prefer Coach"
                            }
                            value={this.state.regdetail.PCoa}
                            onChange={this.handleChange2}
                          />
                          {ssm_valid.showerror("PCoa", this.errors)}
                        </div>
                      )}
                      {!this.check_child(
                        this.state.node,
                        this.state.selregperson.BDay
                      ) && (
                        <div>
                          {(this.state.node.OType === Constant.TP_EVENT ||
                            this.state.node.OType === Constant.TP_SPORT) &&
                            this.state.node.Common.AWCoac !== 1 && (
                              <div>
                                <Checkbox
                                  id="W2Coa"
                                  label={
                                    this.state.node.OType === Constant.TP_EVENT
                                      ? "Willing to Manage"
                                      : "Willing to Head Coach"
                                  }
                                  isSelected={this.state.regdetail.W2Coa}
                                  onCheckboxChange={this.handleCheckboxChange2}
                                  key="W2Coa"
                                />
                              </div>
                            )}
                          {(this.state.node.OType === Constant.TP_EVENT ||
                            this.state.node.OType === Constant.TP_CLASS ||
                            this.state.node.OType === Constant.TP_SPORT) &&
                            this.state.node.Common.AWVolu !== 1 && (
                              <div>
                                <Checkbox
                                  id="W2Vol"
                                  label="Willing to Volunteer"
                                  isSelected={this.state.regdetail.W2Vol}
                                  onCheckboxChange={this.handleCheckboxChange2}
                                  key="W2Vol"
                                />
                              </div>
                            )}
                          {this.state.node.OType === Constant.TP_SPORT &&
                            this.state.node.Common.AWOffi !== 1 && (
                              <div>
                                <Checkbox
                                  id="W2Off"
                                  label="Willing to Officiate"
                                  isSelected={this.state.regdetail.W2Off}
                                  onCheckboxChange={this.handleCheckboxChange2}
                                  key="W2Off"
                                />
                              </div>
                            )}
                          {this.state.node.OType === Constant.TP_SPORT &&
                            this.state.node.Common.AWComm !== 1 && (
                              <div>
                                <Checkbox
                                  id="W2Com"
                                  label="Willing to Commissioner"
                                  isSelected={this.state.regdetail.W2Com}
                                  onCheckboxChange={this.handleCheckboxChange2}
                                  key="W2Com"
                                />
                              </div>
                            )}
                          {this.state.node.OType === Constant.TP_CLASS &&
                            this.state.node.Common.AWCoac !== 1 && (
                              <div>
                                <Checkbox
                                  id="W2Tea"
                                  label="Willing to Teach"
                                  isSelected={this.state.regdetail.W2Tea}
                                  onCheckboxChange={this.handleCheckboxChange2}
                                  key="W2Tea"
                                />
                              </div>
                            )}
                        </div>
                      )}
                      {this.checkfees() && (
                        <div>
                          <Price2
                            node={this.state.node}
                            onOkClick={this.handlePriceOkClick2}
                          />
                          {ssm_valid.showerror("Fee", this.errors)}
                        </div>
                      )}
                      {getfeeName(
                        this.state.node,
                        this.state.regdetail.fee?.FId
                      ) === "Find Team" && (
                        <div>
                          <TeamsSelect
                            node={this.state.node}
                            onHandleTeamChange={this.handleTeamChange}
                          />
                          {ssm_valid.showerror("Teams", this.errors)}
                        </div>
                      )}
                    </div>
                  )}
                </form>
              </div>
            </div>
            <Modal isOpen={this.state.modal}>
              <ModalHeader>
                <h5
                  className="modal-title text-danger"
                  id="addMembersModalTitle"
                ></h5>
                <button type="button" className="close" onClick={this.toggle}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody scroll={false}>
                <div className="d-flex justify-content-center mt-3 mb-3">
                  <span className="text-danger">{this.state.modalError}</span>
                </div>
              </ModalBody>
              <ModalFooter />
            </Modal>
          </>
        );
      case 1:
        if (!this.state.selparent.Addr) {
          this.setState({
            selparent: {
              ...this.state.selparent,
              Addr: this.state.selregperson.Addr,
            },
          });
        }
        if (!this.state.selparent.City) {
          this.setState({
            selparent: {
              ...this.state.selparent,
              City: this.state.selregperson.City,
            },
          });
        }
        if (!this.state.selparent.Regi) {
          this.setState({
            selparent: {
              ...this.state.selparent,
              Regi: this.state.selregperson.Regi,
            },
          });
        }
        if (!this.state.selparent.PCode) {
          this.setState({
            selparent: {
              ...this.state.selparent,
              PCode: this.state.selregperson.PCode,
            },
          });
        }
        if (!this.state.selparent.Num) {
          this.setState({
            selparent: {
              ...this.state.selparent,
              Num: this.state.selregperson.Num,
            },
          });
        }
        return (
          <div id="step-two" className="row">
            <div className="col-12">
              <form>
                {this.getRegsSel2_v()}
                <input
                  className="form-control login-inputs"
                  disabled={
                    this.state.inputsEnable && this.state.adultsListLength > 0
                  }
                  type="text"
                  maxLength={50}
                  name="Emai"
                  placeholder="Email*"
                  value={this.state.selparent.Emai}
                  onChange={this.handleChange3}
                />
                <input
                  className="form-control login-inputs"
                  disabled={
                    this.state.inputsEnable && this.state.adultsListLength > 0
                  }
                  type="text"
                  name="FName"
                  maxLength={64}
                  placeholder="First Name*"
                  value={this.state.selparent.FName}
                  onChange={this.handleChange3}
                />
                <input
                  className="form-control login-inputs"
                  disabled={
                    this.state.inputsEnable && this.state.adultsListLength > 0
                  }
                  type="text"
                  maxLength={64}
                  name="LName"
                  placeholder="Last Name*"
                  value={this.state.selparent.LName}
                  onChange={this.handleChange3}
                />
                <InputMask
                  className="form-control login-inputs"
                  mask="99/99/9999"
                  name="BDay"
                  placeholder="Date of Birth"
                  value={this.state.selparent.BDay}
                  onChange={this.handleChange3}
                />
                {ssm_valid.showerror("BDay", this.errors)}
                <div style={{ marginBottom: 15 }}>
                  <Radiobtn
                    label1="Male"
                    label2="Female"
                    req={this.state.node.Common.AGend == 3 ? "*" : ""}
                    value={this.state.selparent.Gend}
                    onRadioChange={this.handleRadioChange2}
                  />
                </div>
                {ssm_valid.showerror("Gend", this.errors)}
                <div className="addbox mt-2">
                  <input
                    className="form-control login-inputs"
                    type="text"
                    name="Addr"
                    placeholder="Street"
                    maxLength={128}
                    value={this.state.selparent.Addr}
                    onChange={this.handleChange3}
                  />
                  {ssm_valid.showerror("Addr", this.errors)}
                </div>
                <input
                  className="form-control login-inputs"
                  type="text"
                  name="City"
                  placeholder="City"
                  maxLength={64}
                  value={this.state.selparent.City}
                  onChange={this.handleChange3}
                />
                {ssm_valid.showerror("City", this.errors)}
                {this.getState()}
                {ssm_valid.showerror("Regi", this.errors)}
                <InputMask
                  className="form-control login-inputs"
                  mask="99999"
                  type="text"
                  name="PCode"
                  placeholder="Zip Code"
                  value={this.state.selparent.PCode}
                  onChange={this.handleChange3}
                />
                {ssm_valid.showerror("PCode", this.errors)}
                <InputMask
                  className="form-control login-inputs"
                  mask="(999)999-9999"
                  type="text"
                  name="Num"
                  placeholder="Phone"
                  value={this.state.selparent.Num}
                  onChange={this.handleChange3}
                />
                {ssm_valid.showerror("Num", this.errors)}
                <Checkbox
                  id="Sms"
                  label="Allow text messages"
                  isSelected={this.state.selparent.Sms === 1}
                  onCheckboxChange={this.handleCheckboxChange4}
                  key="Sms"
                />
                {this.state.selparent.Sms === 1 && (
                  <div>
                    {this.getCarrierStepTwo()}
                    {ssm_valid.showerror("Gate", this.errors)}
                  </div>
                )}

                {(this.state.node.OType === Constant.TP_EVENT ||
                  this.state.node.OType === Constant.TP_SPORT) &&
                  this.state.node.Common.AWCoac !== 1 && (
                    <div>
                      <Checkbox
                        id="W2Coa"
                        label={
                          this.state.node.OType === Constant.TP_EVENT
                            ? "Willing to Manage"
                            : "Willing to Head Coach"
                        }
                        isSelected={this.state.regdetail.W2Coa}
                        onCheckboxChange={this.handleCheckboxChange2}
                        key="W2Coa"
                      />
                    </div>
                  )}
                {(this.state.node.OType === Constant.TP_EVENT ||
                  this.state.node.OType === Constant.TP_CLASS ||
                  this.state.node.OType === Constant.TP_SPORT) &&
                  this.state.node.Common.AWVolu !== 1 && (
                    <div>
                      <Checkbox
                        id="W2Vol"
                        label="Willing to Volunteer"
                        isSelected={this.state.regdetail.W2Vol}
                        onCheckboxChange={this.handleCheckboxChange2}
                        key="W2Vol"
                      />
                    </div>
                  )}
                {this.state.node.OType === Constant.TP_SPORT &&
                  this.state.node.Common.AWOffi !== 1 && (
                    <div>
                      <Checkbox
                        id="W2Off"
                        label="Willing to Officiate"
                        isSelected={this.state.regdetail.W2Off}
                        onCheckboxChange={this.handleCheckboxChange2}
                        key="W2Off"
                      />
                    </div>
                  )}
                {this.state.node.OType === Constant.TP_SPORT &&
                  this.state.node.Common.AWComm !== 1 && (
                    <div>
                      <Checkbox
                        id="W2Com"
                        label="Willing to Commissioner"
                        isSelected={this.state.regdetail.W2Com}
                        onCheckboxChange={this.handleCheckboxChange2}
                        key="W2Com"
                      />
                    </div>
                  )}
                {this.state.node.OType === Constant.TP_CLASS &&
                  this.state.node.Common.AWCoac !== 1 && (
                    <div>
                      <Checkbox
                        id="W2Tea"
                        label="Willing to Teach"
                        isSelected={this.state.regdetail.W2Tea}
                        onCheckboxChange={this.handleCheckboxChange2}
                        key="W2Tea"
                      />
                    </div>
                  )}
              </form>
            </div>
          </div>
        );
      case 2:
        return (
          <div id="step-three" className="row">
            <div className="col-12">
              <form>
                <input
                  onChange={this.handleteamnameChange}
                  className="form-control login-inputs"
                  type="text"
                  name="Teamname"
                  maxLength={128}
                  placeholder="Team Name"
                />
                {ssm_valid.showerror("Teamname", this.errors)}
                <Checkbox
                  id="Coach"
                  label="As a Coach/Captain"
                  isSelected={this.state.groupChecks.Coach}
                  onCheckboxChange={this.handleGroupCheckboxChange}
                  key="Coach"
                />
                <Checkbox
                  id="Player"
                  label="As a Player"
                  isSelected={this.state.groupChecks.Player}
                  onCheckboxChange={this.handleGroupCheckboxChange}
                  key="Player"
                />
                <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                  <span className="section-title">Team members</span>
                  <button
                    type="button"
                    className="btn add-button d-flex justify-content-center align-items-center rounded"
                    onClick={this.toggle}
                  >
                    <Add htmlColor="#fff" sx={{ fontSize: 30 }} />
                  </button>
                </div>
                {this.state.coachs.map((item, index) => (
                  <MemberCard
                    type={`Coach ${index + 1}`}
                    name={`${item.FName} ${item.LName}`}
                    show={item.PId === 0 && true}
                    onRemove={() => {
                      const groupIndex = this.state.groupPersons.indexOf(item);
                      const newGroupPersons = removeItem(
                        this.state.groupPersons,
                        groupIndex
                      );
                      this.setState({
                        coachs: this.state.coachs.filter((_, i) => index !== i),
                        groupPersons: [...new Set(newGroupPersons)],
                      });
                    }}
                  />
                ))}
                {this.state.players.map((item, index) => (
                  <MemberCard
                    type={`Player ${index + 1}`}
                    name={`${item.FName} ${item.LName}`}
                    show={item.PId === 0 && true}
                    onRemove={() => {
                      const groupIndex = this.state.groupPersons.indexOf(item);
                      const newGroupPersons = removeItem(
                        this.state.groupPersons,
                        groupIndex
                      );
                      this.setState({
                        players: this.state.players.filter(
                          (_, i) => index !== i
                        ),
                        groupPersons: [...new Set(newGroupPersons)],
                      });
                    }}
                  />
                ))}
                {this.state.groupError !== "" && (
                  <div className="d-flex justify-content-center bg-danger rounded mb-3 p-2">
                    <span className="text-white">{this.state.groupError}</span>
                  </div>
                )}
                <Modal isOpen={this.state.modal}>
                  <ModalHeader>
                    <h5 className="modal-title" id="addMembersModalTitle">
                      Add member
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={this.toggle}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </ModalHeader>
                  <ModalBody>
                    <span className="input-title">Member Type</span>
                    <div className="d-flex flex-row mt-1">
                      <div className="mr-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="memberRadio"
                          id="playerRadio"
                          onChange={this.handleMemberTypeRadio}
                          value="10"
                          checked={this.state.teamMember.Type === 10 && true}
                        />
                        <label className="form-check-label" for="playerRadio">
                          Player
                        </label>
                      </div>
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="memberRadio"
                          id="coachRadio"
                          onChange={this.handleMemberTypeRadio}
                          value="11"
                          checked={this.state.teamMember.Type === 11 && true}
                        />
                        <label className="form-check-label" for="coachRadio">
                          Coach/Captain
                        </label>
                      </div>
                    </div>
                    <div className="mt-2">
                      <span className="input-title">Gender</span>
                    </div>
                    <div className="d-flex flex-row mt-1">
                      <div className="mr-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="genderRadio"
                          id="maleRadio"
                          onChange={this.handleMemberGenderRadio}
                          value="1"
                          checked={this.state.teamMember.Gend === "1" && true}
                        />
                        <label className="form-check-label" for="maleRadio">
                          Male
                        </label>
                      </div>
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="genderRadio"
                          id="femaleRadio"
                          onChange={this.handleMemberGenderRadio}
                          value="2"
                          hecked={this.state.teamMember.Gend === "2" && true}
                        />
                        <label className="form-check-label" for="femaleRadio">
                          Female
                        </label>
                      </div>
                    </div>
                    <input
                      onChange={this.handleMemberChange}
                      className="form-control login-inputs"
                      type="text"
                      name="Emai"
                      maxLength={128}
                      placeholder="Email"
                      value={this.state.teamMember.Emai}
                    />
                    {this.state.isExistingEmail && (
                      <div className="rounded email-exist">
                        <span>
                          This is an existing user. Be sure to enter first and
                          last name correctly
                        </span>
                      </div>
                    )}
                    <input
                      onChange={this.handleMemberChange}
                      className="form-control login-inputs"
                      type="text"
                      name="FName"
                      maxLength={128}
                      placeholder="First Name"
                      value={this.state.teamMember.FName}
                    />
                    <input
                      onChange={this.handleMemberChange}
                      className="form-control login-inputs"
                      type="text"
                      name="LName"
                      maxLength={128}
                      placeholder="Last Name"
                      value={this.state.teamMember.LName}
                    />
                    <InputMask
                      className="form-control login-inputs"
                      mask="(999)999-9999"
                      type="text"
                      name="Num"
                      placeholder="Phone"
                      value={this.state.teamMember.Num}
                      onChange={this.handleMemberChange}
                    />
                    {this.state.showModalError !== "" && (
                      <div className="d-flex justify-content-center mt-3">
                        <span className="text-danger">
                          {this.state.modalError}
                        </span>
                      </div>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <button
                      type="button"
                      className="btn filled-button-continue"
                      onClick={this.handleAddNewMember}
                    >
                      Add
                    </button>
                  </ModalFooter>
                </Modal>
              </form>
            </div>
          </div>
        );
      case 3:
        return (
          <div id="step-three" className="row">
            <div className="col-12">
              <CustomQuestion
                node={this.state.node}
                errors={this.state.cqerrors}
                CustomQuestions={this.state.customquestions}
                onAnswerChange={this.handleAnswerChange}
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div id="step-three" className="row">
            <div className="col-12">
              <form>
                {this.state.node?.Waivers[this.state?.toWaiver - 1]?.Body}
              </form>
            </div>
          </div>
        );
    }
  }
  render() {
    if (!this.state.node) {
      return <div></div>;
    }
    let lnode;
    let lname;
    switch (this.state.node.OType) {
      case Constant.TP_VENUE:
        lnode = this.state.node.Venue;
        lname = this.state.node.Venue.Name;
        break;
      case Constant.TP_ITEM:
        lnode = this.state.node.Item;
        lname = this.state.node.Item.Name;
        break;
      case Constant.TP_SPORT:
        lname = this.state.node.Activity.Name;
      case Constant.TP_CLASS:
        lname = this.state.node.Activity.Name;
      case Constant.TP_EVENT:
        lnode = this.state.node.Activity;
        lname = this.state.node.Activity.Name;
        break;
    }
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.errormsg != "" ? (
          <ErrorMsg msg={this.state.errormsg} />
        ) : (
          this.getMainContent(lnode, lname)
        )}
      </div>
    );
  }
}

export default Register;
