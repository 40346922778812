import React from "react";
import Communicate from "../../../../common/components/communicate";
import { checkIsCoach } from "../../../../common/utils/family";

class MyStuff_coach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myregmanobj: this.props.myregmanobj,
      toGo: 0,
      coaches: [],
      mypid: this.props.mypid,
      family: this.props.family,
    };
    this.handleCommunicate = this.handleCommunicate.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  getCoachesState() {
    let coaches = [];
    for (let i = 0; i < this.state.myregmanobj.grs.length; i++) {
      for (let j = 0; j < this.state.myregmanobj.grs[i].GPers.length; j++) {
        if (this.state.myregmanobj.grs[i].GPers[j].PType == 11) {
          coaches.push(this.state.myregmanobj.grs[i].GPers[j]);
        }
      }
    }
    this.setState({ coaches: coaches });
  }

  componentDidMount() {
    this.getCoachesState();
  }

  getPersoninfo(person, teamname) {
    return (
      <div className="row activity-table-row">
        <div
          id="roster-gender"
          className="col-4 activity-col d-flex align-items-center"
        >
          <span className="roster-info">{teamname}</span>
        </div>
        <div
          id="roster-name"
          className="col-4 activity-col d-flex align-items-center"
        >
          <span className="roster-info">
            {person.FName + " " + person.LName}
          </span>
        </div>
        <div
          id="roster-age"
          className="col-4 activity-col d-flex align-items-center"
        >
          <span className="roster-info">{person.Num}</span>
        </div>
      </div>
    );
  }

  getGPersoninfo(GPers, teamname) {
    const items = [];
    for (let i = 0; i < GPers.length; i++) {
      if (GPers[i].PType == 11)
        items.push(this.getPersoninfo(GPers[i].Person, teamname));
    }
    return <div>{items}</div>;
  }

  getCoaches() {
    const items = [];
    for (let i = 0; i < this.state.myregmanobj.grs.length; i++) {
      items.push(
        this.getGPersoninfo(
          this.state.myregmanobj.grs[i].GPers,
          this.state.myregmanobj.grs[i].Group.Name
        )
      );
    }
    return <div>{items}</div>;
  }

  handleCommunicate() {
    this.setState({ toGo: 1 });
  }

  handleBackClick() {
    this.setState({ toGo: 0 });
  }

  render() {
    const isCoach = checkIsCoach(this.state.coaches, this.state.mypid);
    return (
      <div>
        {this.state.toGo == 1 ? (
          <Communicate
            myroster={this.state.coaches}
            mypid={this.state.mypid}
            onBackClick={this.handleBackClick}
            family={this.props.family}
          />
        ) : (
          <>
            <div class="activity-card-header rounded">
              <div className="row">
                <div className="col-4">
                  <span className="activity-column-titles">Team</span>
                </div>
                <div className="col-4 ">
                  <span className="activity-column-titles">Coaches</span>
                </div>
                <div className="col-4 ">
                  <span className="activity-column-titles">Phone</span>
                </div>
              </div>
            </div>
            <div class="card-body">{this.getCoaches()}</div>

            {isCoach && (
              <div className="form-actions-area">
                <a>
                  <button
                    onClick={this.handleCommunicate}
                    className="filled-button-continue"
                    type="button"
                  >
                    Communicate
                  </button>
                </a>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default MyStuff_coach;
