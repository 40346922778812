import { ssm_getcookie } from "../../common/utils/ssm_util";
import { fetchAPI } from "../config";

export async function sendMessage(message, desmg) {
  let jsonstr = JSON.stringify(message);
  jsonstr = new Buffer(jsonstr).toString("base64");
  let sid = ssm_getcookie("ssm_sid");
  const para = { jsonstr, desmg, sid };
  const data = await fetchAPI("/ssm_api_user/sendmessage", para);
  return data;
}
